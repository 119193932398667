import {MatDialogRef, MatSnackBar} from '@angular/material';
import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from '../../../services/storage.service';
import {UserService} from '../../../services/user.service';
import {AuthService} from '../../../services/auth.service';
import {isNullOrUndefined} from 'util';


@Component({
    selector: 'app-change-activation',
    templateUrl: './change-activation.template.html'
})
export class ChangeActivationComponent {

    public user: any;
    public action: string = 'deactivate';
    ownerId: string;
    password: string;
    explainMessage: string = '';

    constructor(public dialogRef: MatDialogRef<ChangeActivationComponent>,
                public translate: TranslateService,
                private storage: StorageService,
                private authService: AuthService,
                private userService: UserService,
                private snackBar: MatSnackBar) {
        this.ownerId = this.storage.getItem('userId');
        dialogRef.afterOpen().subscribe((result) => {
            if (this.ownerId === this.user.id) {
                this.action = 'deactivate';
            } else {
                if (this.user.status === 'inactive') {
                    this.action = 'activate';
                } else {
                    this.action = 'deactivate';
                }
            }
        });
    }

    public changeActivation() {
        if (this.ownerId === this.user.id) {
            this.deactivate(this.password);
        } else {
            if (this.user.status === 'inactive') {
                this.activate();
            } else {
                this.deactivate();
            }
        }
    }

    private deactivate(userPassword?: string) {
        if (!isNullOrUndefined(this.explainMessage) && this.explainMessage !== '') {
            this.userService.deactivateAccount(this.user.id, this.explainMessage, userPassword)
                .subscribe(
                    (result) => {
                        this.dialogRef.close();
                        if (result.status && this.ownerId === this.user.id) {
                            this.authService.clearStorage();
                            window.location.reload();
                        }
                    },
                    (err) => {
                        this.snackBar.open(err, this.translate.instant('CLOSE'), {duration: 3000});
                    });
            return;
        }
        this.snackBar.open(this.translate.instant('ADMIN_STUFF.ERROR'), this.translate.instant('CLOSE'), {duration: 3000});
    }

    private activate() {
        if (!isNullOrUndefined(this.explainMessage) && this.explainMessage !== '') {
            this.userService.activateInactiveAccount(this.user.id, this.explainMessage)
                .subscribe(
                    (result) => {
                        this.dialogRef.close();
                    },
                    (err) => {
                        this.snackBar.open(err, this.translate.instant('CLOSE'), {duration: 3000});
                    });
            return;
        }
        this.snackBar.open(this.translate.instant('ADMIN_STUFF.ERROR'), this.translate.instant('CLOSE'), {duration: 3000});
    }
}
