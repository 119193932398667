import {AfterViewInit, Component, ElementRef, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';

import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from '../../../services/storage.service';
import {LoggerService} from '../../../services/logger.service';
import {UserService} from '../../../services/user.service';
import {AuthService} from '../../../services/auth.service';
import {BaseHeaderComponent} from '../layouts/base-header/base-header.component';
import {SigninDialogComponent} from '../signin/signin-dialog.component';

@Component({
    selector: 'app-marketplace-header',
    templateUrl: 'marketplace-header.template.html'
})
export class MarketplaceHeaderComponent extends BaseHeaderComponent implements OnInit, AfterViewInit {
    isAuthenticated: boolean = false;
    name: string;
    email: string;
    env: any = environment;
    langs: any[] = this.env.supportedLangs;
    showSessions: boolean = false;
    selectedCategory: string;
    lang: string = '';
    public avatarDirName: string;
    public avatarFileName: string;
    private showUserPanelActions: boolean = false;
    private signUpDialogRef: MatDialogRef<SigninDialogComponent>;

    constructor(public translate: TranslateService,
                public snackBar: MatSnackBar,
                protected router: Router,
                protected storage: StorageService,
                private authService: AuthService,
                private logger: LoggerService,
                private userService: UserService,
                protected element: ElementRef,
                public dialog: MatDialog) {
        super(storage, router, element, translate);
        this.isAuthenticated = this.storage.getItem('isLoggedin') === 'true';
        this.lang = localStorage.getItem('lang');
    }

    ngOnInit() {
        if (this.isAuthenticated) {
            this.getCurrentUser();
        }
    }

    getCurrentUser() {
        this.userService.getCurrentUser()
            .subscribe((user) => {
                    if (user.firstName) {
                        this.email = user.email;
                        this.name = `${user.firstName} ${user.lastName}`;
                        this.role = user.role;
                        this.avatarDirName = user.avatarDirName;
                        this.avatarFileName = user.avatarFileName;
                    }
                },
                (err) => {
                    this.snackBar.open(err, this.translate.instant('CLOSE'), {duration: 3000});
                });
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
    }

    footerToggle() {
        this.showUserPanelActions = !this.showUserPanelActions;
    }

    signout() {
        this.authService.logout()
            .subscribe(
                (result) => {
                    window.location.reload();
                },
                (err) => {
                    this.logger.error(err);
                });
    }

    signin() {
        this.signUpDialogRef = this.dialog.open(SigninDialogComponent, {panelClass: 'signin-panel', closeOnNavigation: true});

        this.signUpDialogRef.componentInstance.close.subscribe({
            next: (isAuthenticated) => {
                this.signUpDialogRef.close();
                this.isAuthenticated = isAuthenticated;

                if (this.isAuthenticated) {
                    this.getCurrentUser();
                }
            }
        });
    }

    onSessionToggle() {
        this.showSessions = !this.showSessions;
    }
}
