import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {NetworkHelper} from '../helpers/network.helper';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class VoteService {
    private readonly hostUrl: string;

    constructor(private router: Router, private networkHelper: NetworkHelper) {
        this.hostUrl = environment.host;
    }

    getCurrencies(page: number, itemsPerPage: number, status: string, requestorId?: string): Observable<any> {
        let url = `${this.hostUrl}/currency/all/${page}/${itemsPerPage}/${status}`;
        if (requestorId) {
            url += `/${requestorId}`;
        }

        return this.networkHelper.get(url, {});
    }

    getVotedCurrencies(): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/currency/user-votings`, {});
    }
    voteForCurrency(currencyId: string): Observable<any> {
        return this.networkHelper.patch(`${this.hostUrl}/currency/vote-for-currency/${currencyId}`, {});
    }
}
