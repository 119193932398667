import {Injectable} from '@angular/core';
import {NetworkHelper} from '../helpers/network.helper';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../environments/environment';
import {StorageService} from './storage.service';

interface ProductUpsellsNodeInfo {
    id: string;
    side: 'yes' | 'no';
}

@Injectable()
export class OrderService {
    private readonly hostUrl: string;

    constructor(private networkHelper: NetworkHelper, private storage: StorageService) {
        this.hostUrl = environment.host;
    }

    create(productId: string, quantity: number, captcha: string, currency: string,
           paymentType: string, contactInfo?: any, affRef?: string, couponId?: string): Observable<any> {
        const body: any = {
            contactInfo: contactInfo,
            currency: currency,
            paymentType: paymentType,
            productId: productId,
            quantity: quantity,
            affRef: affRef,
            couponId: couponId,
            captcha: captcha
        };
        return this.networkHelper.post(`${this.hostUrl}/order/create`, body);
    }

    getUpsell(orderId: string, quantity: number, upsellsTreeBranch: ProductUpsellsNodeInfo[],
              affRef?: string, couponId?: string): Observable<any> {
        const body: any = {
            orderId: orderId,
            quantity: quantity,
            upsellsTreeBranch: upsellsTreeBranch,
            affRef: affRef,
            couponId: couponId
        };

        return this.networkHelper.post(`${this.hostUrl}/order/get-upsell`, body);
    }

    get(id: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/order/${id}`);
    }

    getByInvoiceNumber(invoiceNumber: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/order/get-by-invoice-number/${invoiceNumber}`);
    }

    all(mode: string, userId: string, page: number, itemsPerPage: number, status: string,
        paymentStatus: string, refundStatus: string, distribution: string,
        sortType: string, sortDir: string, startDate?: number, endDate?: number): Observable<any> {

        const query: any = {
            mode,
            userId,
            page,
            itemsPerPage,
            status,
            paymentStatus,
            refundStatus,
            distribution,
            sortType,
            sortDir
        };

        if (startDate && endDate) {
            query.startDate = +startDate;
            query.endDate = +endDate;
        }
        return this.networkHelper.get(`${this.hostUrl}/order/all`, query);
    }

    fulfillDemo(invoiceNumber: string, status: string, transactionStatus: string): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/order/fulfill-demo`, {
            transactionStatus: transactionStatus,
            invoiceNumber: invoiceNumber,
            status: status
        });
    }

    markFulfilled(invoiceNumber: string): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/order/mark-fulfilled/${invoiceNumber}`, {});
    }

    refund(invoiceNumber: string, refundReason: string, captcha: string): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/order/refund`, {
            invoiceNumber: invoiceNumber,
            refundReason: refundReason,
            captcha: captcha
        });
    }

    refundDemo(invoiceNumber: string, refundReason: string): Observable<any> {
        const body = {
            invoiceNumber: invoiceNumber,
            refundReason: refundReason
        };

        return this.networkHelper.post(`${this.hostUrl}/order/refund-demo`, body);
    }

    renewDemo(invoiceNumber: string): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/order/renew-demo/${invoiceNumber}`, {});
    }

    markRefunded(invoiceNumber: string): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/order/mark-refunded/${invoiceNumber}`, {});
    }
}
