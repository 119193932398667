import {Component, ElementRef, OnInit, AfterViewInit, ViewChild} from '@angular/core';
import {StorageService} from '../../../../services/storage.service';
import {Router} from '@angular/router';
import {environment} from '../../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-base-header',
    template: ''
})

export class BaseHeaderComponent implements AfterViewInit {
    @ViewChild('headerNavbar', {static: true}) navbar: ElementRef;

    showNavDashboard = false;
    userId: string;
    role: string;
    langs: any = environment.supportedLangs;
    lang: string = '';

    constructor(protected storage: StorageService,
                protected router: Router,
                protected element: ElementRef,
                protected translate: TranslateService) {
        this.userId = this.storage.getItem('userId');
        this.role = this.storage.getItem('role');

        if (localStorage.getItem('lang')) {
            this.lang = localStorage.getItem('lang');
        } else if (this.translate.getBrowserLang()) {
            this.lang = this.translate.getBrowserLang();
        } else {
            this.lang = 'en';
        }
    }

    ngAfterViewInit() {
        this.navbar.nativeElement.addEventListener('click', this.onNavItemSelect.bind(this));
    }

    onNavToggle() {
        this.showNavDashboard = !this.showNavDashboard;
    }

    onNavItemSelect() {
        if (this.showNavDashboard) {
            this.showNavDashboard = !this.showNavDashboard;
        }
    }
}
