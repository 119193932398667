import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-bar-chart',
    templateUrl: 'bar-chart.component.html'
})

export class BarChartComponent implements OnInit {
    @Input()
    chartData: any[];

    @Input()
    chartLabels: Date[];

    @Input()
    type: string = 'time';

    @Input()
    maintainAspectRatio: boolean = true;

    @Input()
    hasXAxis: boolean = true;

    @Input()
    hasYAxis: boolean = true;

    @Input()
    hasXTick: boolean = true;

    @Input()
    hasYTick: boolean = true;

    chartIsReady = false;

    chartOptions: any;

    chartColors: any[] = [{
        backgroundColor: 'rgba(55,188,237, .3)',
        borderColor: 'rgb(28,146,190)',
        pointBackgroundColor: 'rgba(55,188,237, 0)',
        pointBorderColor: 'rgba(255,104,1, 0)',
        pointHoverBackgroundColor: 'rgb(255,255,255)',
        pointHoverBorderColor: 'rgb(55,188,237)',
        borderWidth: 1
    }];

    ngOnInit() {
        this.chartOptions = {
            scaleShowVerticalLines: false,
            tooltips: {
                mode: 'index',
                intersect: false
            },
            legend: {
                display: false
            },
            responsive: true,
            scales: {
                xAxes: [{
                    gridLines: {
                        color: 'rgba(0,0,0,0.02)',
                        zeroLineColor: 'rgba(0,0,0,0.02)'
                    },
                    stacked: true,
                    ticks: {
                        beginAtZero: true,
                        autoSkip: true
                    },
                    type: this.type
                }],
                yAxes: [{
                    gridLines: {
                        color: 'rgba(0,0,0,0.02)',
                        zeroLineColor: 'rgba(0,0,0,0.02)'
                    },
                    stacked: true
                }]
            }
        };

        this.chartIsReady = true;
    }
}
