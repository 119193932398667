import {Component, OnInit, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: 'confirmation-dialog.component.html'
})
export class ConfirmationDialogComponent implements OnInit {

    title: string = '';
    text: string = '';
    approveText: string = '';   // approve button text
    cancelText: string = '';    // cancel button text

    constructor(@Inject(MAT_DIALOG_DATA) public data,
                public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
                public snackBar: MatSnackBar,
                public translate: TranslateService) {
                    this.title = data.title;
                    this.text = data.text;
                    this.approveText = data.approve;
                    this.cancelText = data.cancel;
    }

    ngOnInit() {
    }

    cancel() {
        this.dialogRef.close();
    }

    confirm() {
        this.dialogRef.close({success: true});
    }

}
