import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {StorageService} from './storage.service';


@Injectable()
export class MerchantDashboardResolver implements Resolve<any> {
    constructor(private router: Router, private storage: StorageService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        if (this.storage.getItem('role') === 'merchant') {
            const userId = this.storage.getItem('userId');
            this.router.navigate([`/merchant/dashboard`]);
        }
        return null;
    }
}
