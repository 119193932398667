export class PaymentTypes {
    public static list: any = [
        {
            name: 'PAYMENT.TEST',
            code: 'TEST',
            value: 0,
            img: '../../../assets/images/payment-logo/test.svg'
        },
        {
            name: 'PAYMENT.CARD',
            code: 'CREDITCARD',
            value: 6,
            img: '../../../assets/images/payment-logo/credit_card.svg'
        },
        {
            name: 'PAYMENT.PAY_PAL',
            code: 'PAY_PAL',
            value: 34,
            img: '../../../assets/images/payment-logo/paypal.svg'
        },
        {
            name: 'PAYMENT.ONLINE_BANK_TRANSFER_SOFORTUBERWEISUNG',
            code: 'ONLINE_BANK_TRANSFER_SOFORTUBERWEISUNG',
            value: 33,
            img: '../../../assets/images/payment-logo/sofort.svg'
        },
        {
            name: 'PAYMENT.ONLINE_BANK_TRANSFER_EPS',
            code: 'ONLINE_BANK_TRANSFER_EPS',
            value: 50,
            img: '../../../assets/images/payment-logo/bank_transfer.svg'
        },
        {
            name: 'PAYMENT.DIRECT_DEBIT_SEPA',
            code: 'DIRECT_DEBIT_SEPA',
            value: 37,
            img: '../../../assets/images/payment-logo/debit_sepa.svg',
        },
        {
            name: 'PAYMENT.INVOICE_PREPAYMENT',
            code: 'INVOICE_PREPAYMENT',
            value: 27,
            img: '../../../assets/images/payment-logo/invoice.svg'
        }
    ];
}
