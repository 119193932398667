import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../../services/auth.service';
import {UserService} from '../../../../services/user.service';
import {MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from '../../../../services/storage.service';
import {LoggerService} from '../../../../services/logger.service';
import {ConfigurationService} from '../../../../services/configuration.service';
import {AuthLayoutComponent} from '../auth-layout/auth-layout.component';

@Component({
    selector: 'app-merchant-layout',
    templateUrl: './merchant-layout.template.html'
})
export class MerchantLayoutComponent extends AuthLayoutComponent implements OnInit {

    user: any = {};
    currentLang: string = '';
    isAdmin = false;

    constructor(protected router: Router,
                public route: ActivatedRoute,
                public translate: TranslateService,
                protected authService: AuthService,
                protected userService: UserService,
                protected storage: StorageService,
                public snackBar: MatSnackBar,
                private logger: LoggerService,
                protected configuration: ConfigurationService) {
        super(router, route, translate, storage, configuration, authService, userService);

        this.isAdmin = ['admin', 'superadmin'].includes(this.storage.getItem('role'));
    }

    setLang() {
        localStorage.setItem('lang', this.currentLang);
        this.translate.use(this.currentLang);
    }

    ngOnInit() {
        super.ngOnInit();

        if (localStorage.getItem('lang')) {
            this.currentLang = localStorage.getItem('lang');
        } else if (this.translate.getBrowserLang()) {
            const langCandidate = this.translate.getBrowserLang();
            if (this.env.supportedLangs.map(langObj => langObj.ISOCode).includes(langCandidate)) {
                this.currentLang = langCandidate;
            } else {
                this.currentLang = 'en';
            }
        } else {
            this.currentLang = 'en';
        }
        this.translate.use(this.currentLang);

        if ('user' === this.storage.getItem('role') &&
            !this.router.url.includes('merchant/enroll')) {
            this.router.navigate(['/']);
        }
    }

    signout() {
        this.authService.logout()
            .subscribe(
                (result) => {
                    window.location.reload();
                },
                (err) => {
                    this.router.navigate(['/']);
                });
    }
}
