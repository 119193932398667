import {Routes} from '@angular/router';
import {GuestLayoutComponent} from './components/common/layouts/guest-layout/guest-layout.component';
import {MainLayoutComponent} from './components/common/layouts/main-layout/main-layout.component';
import {AdminLayoutComponent} from './components/common/layouts/admin-layout/admin-layout.component';
import {MerchantLayoutComponent} from './components/common/layouts/merchant-layout/merchant-layout.component';
import {GuestService} from './services/guest.service';
import {AuthService} from './services/auth.service';
import {MerchantDashboardResolver} from './services/merchant-dashboard-resolver.service';
import {MarketplaceLayoutComponent} from './components/common/layouts/marketplace-layout/marketplace-layout.component';
import {ICOLandingLayoutComponent} from './components/common/layouts/ico-landing-layout/ico-landing-layout.component';
import {CurrenciesLayoutComponent} from './components/common/layouts/currencies-layout/currencies-layout.component';
import {SalesLayoutComponent} from './components/common/layouts/sales-layout/sales-layout.component';
import {OrderLayoutComponent} from './components/common/layouts/order-layout/order-layout.component';
import {PaymentLayoutComponent} from './components/common/layouts/payment-layout/payment-layout.component';


export const rootRouterConfig: Routes = [
    {
        path: '',
        component: MainLayoutComponent,
        canActivate: [AuthService],
        loadChildren: './views/profile/profile.module#ProfileModule',
        data: {title: 'Profile'},
        resolve: {
            dummy: MerchantDashboardResolver
        }
    },
    {
        path: 'marketplace',
        component: MarketplaceLayoutComponent,
        loadChildren: './views/marketplace/marketplace.module#MarketplaceModule',
        data: {title: 'Your Market'},
    },
    {
        path: 'token',
        component: ICOLandingLayoutComponent,
        loadChildren: './views/ico/ico.module#ICOModule',
        data: {title: 'ICO Landing'},
    },
    {
        path: 'sessions',
        component: GuestLayoutComponent,
        canActivate: [GuestService],
        loadChildren: './views/sessions/sessions.module#SessionsModule',
        data: {title: 'Session'},
    },
    {
        path: 'support',
        component: GuestLayoutComponent,
        loadChildren: './views/general/general.module#GeneralModule'
    },
    {
        path: 'merchant',  // todo Do we need MerchantLayoutComponent, AdminLayoutComponent and others.
        component: MainLayoutComponent,
        canActivate: [AuthService],
        loadChildren: './views/merchant/merchant.module#MerchantModule',
    },
    {
        path: 'affiliate',
        component: MainLayoutComponent,
        canActivate: [AuthService],
        loadChildren: './views/affiliate/affiliate.module#AffiliateModule',
    },
    {
        path: 'dashboard',
        component: MainLayoutComponent,
        canActivate: [AuthService],
        loadChildren: './views/profile/profile.module#ProfileModule',
        data: {title: 'Profile'},
        resolve: {
            dummy: MerchantDashboardResolver
        }
    },
    {
        path: 'user',
        component: MainLayoutComponent,
        canActivate: [AuthService],
        loadChildren: './views/profile/profile.module#ProfileModule'
    },
    {
        path: 'admin',
        component: MainLayoutComponent,
        canActivate: [AuthService],
        loadChildren: './views/admin/admin.module#AdminModule'
    },
    {
        path: 'merchant',
        component: MerchantLayoutComponent,
        canActivate: [AuthService],
        loadChildren: './views/merchant/merchant.module#MerchantModule'
    },
    {
        path: 'sales',
        component: SalesLayoutComponent,
        loadChildren: './views/sales/sales.module#SalesModule'
    },
    {
        path: 'order',
        component: OrderLayoutComponent,
        loadChildren: './views/order/order.module#OrderModule'
    },
    {
        path: 'payment',
        component: PaymentLayoutComponent,
        loadChildren: './views/payment/payment.module#PaymentModule'
    },
    {
        path: 'wallets',
        component: MainLayoutComponent,
        canActivate: [AuthService],
        loadChildren: './views/wallets/wallets.module#WalletsModule'
    },
    {
        path: 'currencies',
        component: CurrenciesLayoutComponent,
        canActivate: [AuthService],
        loadChildren: './views/currencies/currencies.module#CurrenciesModule'
    },
    {
        path: '**',
        redirectTo: 'sessions/404'
    },
];

