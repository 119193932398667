import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../../services/auth.service';
import {StorageService} from '../../../../services/storage.service';
import {ConfigurationService} from '../../../../services/configuration.service';
import {Subscription} from 'rxjs/Subscription';
import {UserService} from '../../../../services/user.service';
import {BaseLayoutComponent} from '../base-layout/base-layout.component';

@Component({
    selector: 'app-auth-layout',
    template: ''
})
export class AuthLayoutComponent extends BaseLayoutComponent implements OnInit, OnDestroy {

    name: string = '';
    email: string = '';

    private getCurrentUserSubscription: Subscription;

    constructor(protected router: Router,
                public  route: ActivatedRoute,
                public translate: TranslateService,
                protected storage: StorageService,
                protected configuration: ConfigurationService,
                protected authService: AuthService,
                protected userService: UserService) {

        super(router, route, translate, storage, configuration, authService);
        /*if (this.storage.getItem('isLoggedin') === 'true') {


            this.getCurrentUserSubscription = this.userService.getCurrentUser()
                .subscribe((res) => {
                    this.name = res.name;
                    this.email = res.email;
            });
        } */
        this.getUserCredentials();
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngOnDestroy() {
        if (this.getCurrentUserSubscription) {
            this.getCurrentUserSubscription.unsubscribe();
        }

        super.ngOnDestroy();
    }

    getUserCredentials(): void {
        if (this.isAuthenticated) {
            this.name = this.storage.getItem('name');
            this.email = this.storage.getItem('email');
        }
    }
}
