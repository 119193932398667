import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {StorageService} from './storage.service';

@Injectable()
export class GuestService implements CanActivate {

    private isAuthenticated = false;

    constructor(private router: Router, private storage: StorageService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.isAuthenticated = this.storage.getItem('isLoggedin') === 'true';

        if (this.isAuthenticated) {
            return false;
        }

        return true;
    }
}
