import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material';
import {RoutePartsService} from './services/route-parts.service';
import {environment} from '../environments/environment';
import 'rxjs/add/operator/filter';
import {AuthService} from './services/auth.service';
import {LoggerService} from './services/logger.service';
import {StorageService} from './services/storage.service';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from './services/user.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    pageTitle = '';

    constructor(public title: Title,
                private router: Router,
                private activeRoute: ActivatedRoute,
                private routePartsService: RoutePartsService,
                public snackBar: MatSnackBar,
                private authService: AuthService,
                private userService: UserService,
                public translate: TranslateService,
                private storage: StorageService,
                private logger: LoggerService) {
    }

    ngOnInit() {
        this.changePageTitle();
    }

    changePageTitle() {
        this.router.events.filter(event => event instanceof NavigationEnd).subscribe((routeChange) => {
            const routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
            if (!routeParts.length) {
                return this.title.setTitle(environment.appTitle);
            }
            // Extract title from parts;
            this.pageTitle = routeParts
                .reverse()
                .map((part) => part.title)
                .reduce((partA, partI) => {
                    return `${partA} > ${partI}`;
                });
            this.pageTitle += ` | ${environment.appTitle}`;
            this.title.setTitle(this.pageTitle);

        });
    }

    signout() {
        this.authService.logout()
            .subscribe(
                (result) => {
                    window.location.reload();
                },
                (err) => {
                    this.logger.error(err);
                });
    }
}
