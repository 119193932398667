import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {isNullOrUndefined} from 'util';
import {AuthService} from '../../../../services/auth.service';
import {StorageService} from '../../../../services/storage.service';
import {ConfigurationService} from '../../../../services/configuration.service';
import {BaseLayoutComponent} from '../base-layout/base-layout.component';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'app-guest-layout',
    templateUrl: './guest-layout.component.html'
})
export class GuestLayoutComponent extends BaseLayoutComponent implements OnInit {

    env: any = environment;
    currentLang: string = 'en';

    constructor(protected router: Router,
                public route: ActivatedRoute,
                public translate: TranslateService,
                protected storage: StorageService,
                protected configuration: ConfigurationService,
                protected authService: AuthService) {
        super(router, route, translate, storage, configuration, authService);
    }

    ngOnInit() {
        super.ngOnInit();

        if (localStorage.getItem('lang')) {
            this.currentLang = localStorage.getItem('lang');
        } else if (this.translate.getBrowserLang()) {
            const langCandidate = this.translate.getBrowserLang();
            if (this.env.supportedLangs.map(langObj => langObj.ISOCode).includes(langCandidate)) {
                this.currentLang = langCandidate;
            } else {
                this.currentLang = 'en';
            }
        } else {
            this.currentLang = 'en';
        }
        this.translate.use(this.currentLang);
    }

    setLang() {
        localStorage.setItem('lang', this.currentLang);
        this.translate.use(this.currentLang);
    }
}
