import {Injectable} from '@angular/core';
import {NetworkHelper} from '../helpers/network.helper';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../environments/environment';
import {StorageService} from './storage.service';

@Injectable()
export class ProductService {
    private readonly hostUrl: string;

    constructor(private networkHelper: NetworkHelper, private storage: StorageService) {
        this.hostUrl = environment.host;
    }

    add(product: any): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/product/add`, product);
    }

    getProductByLink(link: string, includeDistribution: number = 0): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/product/link/${link}/${includeDistribution}`);
    }

    getProduct(id: string, includeDistribution: number = 0): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/product/${id}/${includeDistribution}`);
    }

    all(page: number, itemsPerPage: number, userId: string, categoryId: string, subCategoryId: string, contentCategoryId: string,
        sortType: string, sortDir: string, liveState: string, listInMarketplace: string, keyword: string = '',
        affiliateLevel: number = 0, includeDistributions: number = 0): Observable<any> {

        const query = {
            page,
            itemsPerPage,
            userId,
            categoryId,
            subCategoryId,
            contentCategoryId,
            sortType,
            sortDir,
            liveState,
            listInMarketplace,
            keyword,
            affiliateLevel,
            includeDistributions
        };

        return this.networkHelper.get(`${this.hostUrl}/product/all`, query);
    }

    recycle(productId: string): Observable<any> {
        return this.networkHelper.del(`${this.hostUrl}/product/${productId}`, {});
    }

    editProduct(id: string, product: any): Observable<any> {
        return this.networkHelper.patch(`${this.hostUrl}/product/${id}`, product);
    }

    setAffiliateLevels(id: string, levels: any): Observable<any> {
        return this.networkHelper.patch(`${this.hostUrl}/product/levels/${id}`, levels);
    }

    getProductUpsells(id: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/product/get-upsells/${id}`);
    }
}
