import {AfterViewInit, Component, ElementRef, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material';

import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from '../../../services/storage.service';
import {BaseHeaderComponent} from '../layouts/base-header/base-header.component';

@Component({
    selector: 'app-affiliate-header',
    templateUrl: './affiliate-header.template.html'
})
export class AffiliateHeaderComponent extends BaseHeaderComponent implements AfterViewInit {

    constructor(public translate: TranslateService,
                protected router: Router,
                protected storage: StorageService,
                protected element: ElementRef) {
        super(storage, router, element, translate);
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
    }
}
