import {Injectable} from '@angular/core';
import {NetworkHelper} from '../helpers/network.helper';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../environments/environment';

@Injectable()
export class ConfigurationService {
    public message: string = '';
    private readonly hostUrl: string;

    constructor(private networkHelper: NetworkHelper) {
        this.hostUrl = environment.host;
    }

    setMessage(msg: string): void {
        this.message = msg;
    }

    updateConfiguration(data): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/configuration`, data);
    }

    getAll(page: number, itemsPerPage: number, status: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/configuration/all/${page}/${itemsPerPage}/${status}`, {});
    }

    sendActivation(email: string) {
        return this.networkHelper.post(`${this.hostUrl}/user/send-activation-email`, {email: email});
    }

    getMaintenance(): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/configuration/maintenance`, {});
    }

    getHeight(): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/configuration/get-height`, {});
    }

    getCurrencies(userId: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/configuration/get-currencies/${userId}`, {});
    }
}
