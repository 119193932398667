import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatTabsModule} from '@angular/material/tabs';

import {rootRouterConfig} from './app.routes';
import {AppCommonModule} from './components/common/app-common.module';
import {AppComponent} from './app.component';

import {RoutePartsService} from './services/route-parts.service';
import {AuthService} from './services/auth.service';
import {ContactMessageService} from './services/contact-message.service';
import {GuestService} from './services/guest.service';
import {UserService} from './services/user.service';
import {RatesService} from './services/rates.service';
import {ConfigurationService} from './services/configuration.service';
import {StatsService} from './services/stats.service';
import {StorageService} from './services/storage.service';
import {LoggerService} from './services/logger.service';
import {DataService} from './services/data.service';
import {MerchantService} from './services/merchant.service';
import {ClipboardService} from './services/clipboard.service';
import {TokenInterceptor} from './services/token.interceptor';

import {environment} from '../environments/environment';
import {NetworkHelper} from './helpers/network.helper';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {UploadService} from './services/upload.service';
import {ProductService} from './services/product.service';
import {CategoryService} from './services/category.service';
import {CustomerCenterService} from './services/customer-center.service';
import {MerchantDashboardResolver} from './services/merchant-dashboard-resolver.service';
import {OrderService} from './services/order.service';
import {DistributionService} from './services/distribution.service';
import {AppUtl} from './helpers/app-utl';
import {VoteService} from './services/vote.service';
import {PayoutService} from './services/payout.service';
import {SubscriptionService} from './services/subscription.service';
import {ICOService} from './services/ico.service';
import {MiscService} from './services/misc.service';
import {InvoiceService} from './services/invoice.service';
import {QuillModule} from 'ngx-quill';
import {CouponService} from './services/coupon.service';
import {IntegrationService} from './services/integration.service';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json?version='
        + environment.version);
}

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppCommonModule,
        HttpClientModule,
        MatTabsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        RouterModule.forRoot(rootRouterConfig, {useHash: false}),
        QuillModule,
    ],
    declarations: [AppComponent],
    providers: [
        AppUtl,
        LoggerService,
        MerchantDashboardResolver,
        StorageService,
        RoutePartsService,
        GuestService,
        AuthService,
        ContactMessageService,
        UserService,
        RatesService,
        ConfigurationService,
        StatsService,
        PayoutService,
        InvoiceService,
        VoteService,
        DataService,
        MerchantService,
        NetworkHelper,
        ClipboardService,
        UploadService,
        ProductService,
        OrderService,
        DistributionService,
        CategoryService,
        CustomerCenterService,
        SubscriptionService,
        CouponService,
        ICOService,
        MiscService,
        IntegrationService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
