import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatSnackBar} from '@angular/material';

import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from '../../../services/storage.service';

@Component({
    selector: 'app-selection-tabs',
    templateUrl: './selection-tabs.template.html'
})
export class SelectionTabsComponent {
    public tabList: any[any];
    @Input() notabs: boolean = false;
    @Input() selectedTab: string;
    @Input() selectedText: string;
    @Input() urlBase: string;
    @Input() role: string;
    @Input() activeLink: string;
    @Output() selection: EventEmitter<any> = new EventEmitter();
    env: any = environment;

    private initialSelectedTab = '';

    constructor(public translate: TranslateService,
                public snackBar: MatSnackBar,
                private router: Router,
                private activeRoute: ActivatedRoute,
                public storage: StorageService) {
        this.router.events.filter(event => event instanceof NavigationEnd)
            .subscribe((routeChange) => {
                if (this.activeRoute.snapshot.firstChild &&
                    this.activeRoute.snapshot.firstChild.url &&
                    this.activeRoute.snapshot.firstChild.url.length) {
                    this.initialSelectedTab = this.activeRoute.snapshot.firstChild.url[0].path;
                }
            });
    }

    @Input() set tabs(inputTabs: any[any]) {
        let isSelectedTabVisible: boolean = true;
        let anyVisibleTab: any;
        const visibleTabs: any[any] = [];
        for (const t of inputTabs) {
            if (t.endpoint === this.initialSelectedTab) {
                t.active = true;
            }
            if (t.visible === false) {// compatibility with all usages
                if (this.selectedTab === t.value) {
                    isSelectedTabVisible = false;
                }
                continue;
            }

            if (!anyVisibleTab) {
                anyVisibleTab = t;
            }
            visibleTabs.push(t);
        }

        this.tabList = visibleTabs;

        if (!isSelectedTabVisible) {
            this.selectedTab = anyVisibleTab.value;
        }
    }

    onRouteChange(selectedTab: any) {
        for (const t of this.tabList) {
            t.active = false;
        }
        selectedTab.active = true;
        this.selectedText = selectedTab.text;
        this.selection.emit(selectedTab.text);
        this.router.navigate([`${this.urlBase}${selectedTab.endpoint}`]);
    }

    selectTab(selectedTab: any) {
        this.selectedTab = selectedTab.value;
        this.selection.emit(selectedTab.value);
    }
}
