import {AfterViewInit, Component, ElementRef, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from '../../../services/storage.service';
import {BaseHeaderComponent} from '../layouts/base-header/base-header.component';

@Component({
    selector: 'app-ico-header',
    templateUrl: './ico-header.template.html'
})

export class ICOHeaderComponent extends BaseHeaderComponent implements AfterViewInit {

    constructor(public translate: TranslateService,
                protected router: Router,
                protected storage: StorageService,
                protected element: ElementRef) {
        super(storage, router, element, translate);
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
    }
}
