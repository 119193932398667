import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {NetworkHelper} from '../helpers/network.helper';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class InvoiceService {
    private readonly hostUrl: string;

    constructor(private router: Router, private networkHelper: NetworkHelper) {
        this.hostUrl = environment.host;
    }

    getInvoices(userId: string, currency: string): Observable<any> {
        const url = `${this.hostUrl}/invoice/get-invoices/${userId}/${currency}`;
        return this.networkHelper.get(url);
    }

    all(page: number, itemsPerPage: number, userId: string, status: string,
        sortType: string, sortDir: string, startDate: number, endDate: number): Observable<any> {
        const query: any = {
            page: page,
            itemsPerPage: itemsPerPage
        };

        if (userId) {
            query.userId = userId;
        }
        if (status) {
            query.status = status;
        }
        if (sortType) {
            query.sortType = sortType;
        }
        if (sortDir) {
            query.sortDir = sortDir;
        }
        if (startDate) {
            query.startDate = startDate;
        }
        if (endDate) {
            query.endDate = endDate;
        }
        const url = `${this.hostUrl}/invoice/all`;
        return this.networkHelper.get(url, query);
    }
}
