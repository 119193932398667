import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-simple-header',
  templateUrl: './simple-header.component.html'
})
export class SimpleHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
