import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {FlexLayoutModule} from '@angular/flex-layout';
import {
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatOptionModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatTooltipModule
} from '@angular/material';
import {NavigationComponent} from './navigation/navigation.component';
import {MainLayoutComponent} from './layouts/main-layout/main-layout.component';
import {AdminLayoutComponent} from './layouts/admin-layout/admin-layout.component';
import {ChangeActivationComponent} from './change-activation/change-activation.component';
import {TranslateModule} from '@ngx-translate/core';
import {HttpClientModule} from '@angular/common/http';
import {MerchantLayoutComponent} from './layouts/merchant-layout/merchant-layout.component';
import {GuestLayoutComponent} from './layouts/guest-layout/guest-layout.component';
import {WaitlistComponent} from '../../views/merchant/waitlist/waitlist.component';
import {MerchantEnrollmentComponent} from './merchant-enrollment/merchant-enrollment.component';
import {BaseLayoutComponent} from './layouts/base-layout/base-layout.component';
import {ClipboardDirective} from '../../directives/clipboard.directive';
import {DecimalShrinkerPipe} from '../../pipes/decimal-shrinker.pipe';
import {AuthLayoutComponent} from './layouts/auth-layout/auth-layout.component';
import {UploadDialogComponent} from './uploader/upload-dialog.component';
import {UploadComponent} from './uploader/upload.component';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {ChartsModule} from 'ng2-charts/ng2-charts';
import {UserHeaderComponent} from './user-header/user-header.component';
import {FooterComponent} from './footer/footer.component';
import {MerchantHeaderComponent} from './merchant-header/merchant-header.component';
import {AffiliateHeaderComponent} from './affiliate-header/affiliate-header.component';
import {UserPanelComponent} from './user-panel/user-panel.component';
import {LineChartComponent} from './chart/line-chart.component';
import {BarChartComponent} from './chart/bar-chart.component';
import {PieChartComponent} from './chart/pie-chart.component';
import {RatingComponent} from './rating/rating.component';
import {CategoryMenuComponent} from './category-menu/category-menu.component';
import {SelectionTabsComponent} from './selection-tabs/selection-tabs.component';
import {GuestHeaderComponent} from './guest-header/guest-header.component';
import {MarketplaceLayoutComponent} from './layouts/marketplace-layout/marketplace-layout.component';
import {MarketplaceHeaderComponent} from './marketplace-header/marketplace-header.component';
import {SettingsComponent} from '../../views/profile/settings/settings.component';
import {CurrenciesComponent} from '../../views/currencies/currencies.component';
import {SupportedCurrenciesComponent} from '../../views/currencies/supported/supported-currencies.component';
import {VotesComponent} from '../../views/currencies/votes/votes.component';
import {ICOHeaderComponent} from './ico-header/ico-header.component';
import {ICOLandingLayoutComponent} from './layouts/ico-landing-layout/ico-landing-layout.component';
import {ConfirmAlertComponent} from './confirm-alert/confirm-alert.component';
import {AdminHeaderComponent} from './admin-header/admin-header.component';
import {BaseHeaderComponent} from './layouts/base-header/base-header.component';
import {RemoveBullets} from '../../pipes/remove-bullets.pipe';
import {CurrenciesLayoutComponent} from './layouts/currencies-layout/currencies-layout.component';
import {SigninDialogComponent} from './signin/signin-dialog.component';
import {UpsellConfirmAlertComponent} from './upsell-confirm-alert/upsell-confirm-alert.component';
import {SigninCommonComponent} from './signin/signin-common.component';
import {SalesLayoutComponent} from './layouts/sales-layout/sales-layout.component';
import {OrderLayoutComponent} from './layouts/order-layout/order-layout.component';
import {DebugPipe} from '../../pipes/debug.pipe';
import {UpsellDetailsComponent} from './upsell-details/upsell-details.component';
import {SafeUrlPipe} from '../../pipes/safe-url.pipe';
import { SafeHtmlPipe } from '../../pipes/safe-html';
import {NormalResultPipe} from '../../pipes/normal-result.pipe';
import {PaymentLayoutComponent} from './layouts/payment-layout/payment-layout.component';
import {QuillModule} from 'ngx-quill';
import {SimpleHeaderComponent} from './simple-header/simple-header.component';
import {LanguageSelectorComponent} from './language-selector/language-selector.component';
import {MultipleSelectComponent} from './multiple-select/multiple-select.component';
import {ToFixedPipe} from '../../pipes/to-fixed.pipe';
import {ConfirmationDialogComponent} from './confirmation-dialog/confirmation-dialog.component';
import {RecaptchaModule} from 'ng-recaptcha';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        FlexLayoutModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatTooltipModule,
        MatOptionModule,
        MatSelectModule,
        MatMenuModule,
        MatSnackBarModule,
        MatGridListModule,
        MatButtonModule,
        MatRadioModule,
        MatChipsModule,
        MatCheckboxModule,
        MatCardModule,
        MatDialogModule,
        MatInputModule,
        HttpClientModule,
        TranslateModule,
        MatProgressBarModule,
        ChartsModule,
        QuillModule,
        RecaptchaModule.forRoot(),
        NgCircleProgressModule.forRoot({
            radius: 70,
            space: -10,
            outerStrokeWidth: 15,
            outerStrokeColor: '#35d8b9',
            innerStrokeColor: 'rgba(255, 255, 255, .0)',
            innerStrokeWidth: 10,
            subtitleFontSize: '50',
            subtitleColor: '#a5a5a5',
            animationDuration: 1000,
            renderOnClick: false,
            showTitle: false,
            showUnits: false,
            showBackground: false,
            responsive: true
        })
    ],
    declarations: [
        BaseLayoutComponent,
        GuestLayoutComponent,
        AuthLayoutComponent,
        MainLayoutComponent,
        AdminLayoutComponent,
        MerchantLayoutComponent,
        MarketplaceLayoutComponent,
        OrderLayoutComponent,
        SalesLayoutComponent,
        ICOLandingLayoutComponent,
        CurrenciesLayoutComponent,
        PaymentLayoutComponent,
        NavigationComponent,
        MarketplaceHeaderComponent,
        BaseHeaderComponent,
        UserHeaderComponent,
        AdminHeaderComponent,
        GuestHeaderComponent,
        MerchantHeaderComponent,
        AffiliateHeaderComponent,
        ICOHeaderComponent,
        UserPanelComponent,
        LanguageSelectorComponent,
        FooterComponent,
        ChangeActivationComponent,
        WaitlistComponent,
        MerchantEnrollmentComponent,
        ClipboardDirective,
        DecimalShrinkerPipe,
        DebugPipe,
        SafeUrlPipe,
        SafeHtmlPipe,
        RemoveBullets,
        UploadComponent,
        UploadDialogComponent,
        LineChartComponent,
        BarChartComponent,
        PieChartComponent,
        RatingComponent,
        CategoryMenuComponent,
        SelectionTabsComponent,
        SettingsComponent,
        CurrenciesComponent,
        SupportedCurrenciesComponent,
        VotesComponent,
        ConfirmAlertComponent,
        SigninDialogComponent,
        SigninCommonComponent,
        UpsellConfirmAlertComponent,
        ConfirmationDialogComponent,
        UpsellDetailsComponent,
        NormalResultPipe,
        SimpleHeaderComponent,
        MultipleSelectComponent,
        ToFixedPipe
    ],
    entryComponents: [
        ChangeActivationComponent,
        WaitlistComponent,
        MerchantEnrollmentComponent,
        UploadDialogComponent,
        ConfirmAlertComponent,
        SigninDialogComponent,
        SigninCommonComponent,
        UpsellConfirmAlertComponent,
        ConfirmationDialogComponent,
        UpsellDetailsComponent
    ],
    exports: [
        NavigationComponent,
        MarketplaceHeaderComponent,
        BaseHeaderComponent,
        UserHeaderComponent,
        AdminHeaderComponent,
        GuestHeaderComponent,
        MerchantHeaderComponent,
        AffiliateHeaderComponent,
        UserPanelComponent,
        LanguageSelectorComponent,
        FooterComponent,
        ChangeActivationComponent,
        MerchantEnrollmentComponent,
        ClipboardDirective,
        DecimalShrinkerPipe,
        DebugPipe,
        SafeUrlPipe,
        SafeHtmlPipe,
        RemoveBullets,
        UploadComponent,
        LineChartComponent,
        BarChartComponent,
        PieChartComponent,
        RatingComponent,
        CategoryMenuComponent,
        SelectionTabsComponent,
        ICOHeaderComponent,
        ConfirmAlertComponent,
        SigninDialogComponent,
        SigninCommonComponent,
        UpsellConfirmAlertComponent,
        ConfirmationDialogComponent,
        UpsellDetailsComponent,
        NormalResultPipe,
        MultipleSelectComponent,
        ToFixedPipe
    ]
})
export class AppCommonModule {
}
