import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../../services/auth.service';
import {MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from '../../../../services/storage.service';
import {LoggerService} from '../../../../services/logger.service';
import {ConfigurationService} from '../../../../services/configuration.service';
import {BaseLayoutComponent} from '../base-layout/base-layout.component';

@Component({
    selector: 'app-ico-landing-layout',
    templateUrl: './ico-landing-layout.template.html'
})
export class ICOLandingLayoutComponent extends BaseLayoutComponent implements OnInit {
    user: any = {};

    currentLang: string = '';
    isAdmin = false;

    constructor(protected router: Router,
                public route: ActivatedRoute,
                public translate: TranslateService,
                protected authService: AuthService,
                protected storage: StorageService,
                public snackBar: MatSnackBar,
                protected configuration: ConfigurationService) {
        super(router, route, translate, storage, configuration, authService);

        this.isAdmin = ['admin', 'superadmin'].includes(this.storage.getItem('role'));
    }

    setLang() {
        localStorage.setItem('lang', this.currentLang);
        this.translate.use(this.currentLang);
    }

    ngOnInit() {
        super.ngOnInit();
        if (localStorage.getItem('lang')) {
            this.currentLang = localStorage.getItem('lang');
        } else if (this.translate.getBrowserLang()) {
            const langCandidate = this.translate.getBrowserLang();
            if (this.env.supportedLangs.map(langObj => langObj.ISOCode).includes(langCandidate)) {
                this.currentLang = langCandidate;
            } else {
                this.currentLang = 'en';
            }
        } else {
            this.currentLang = 'en';
        }
        this.translate.use(this.currentLang);
    }
}
