import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Component, Inject, NgZone} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../../services/user.service';
import {LoggerService} from '../../../services/logger.service';

@Component({
    selector: 'merchant-waitlist',
    templateUrl: './waitlist.component.html'
})
export class WaitlistComponent {
    inMerchantWaitlist = false;

    constructor(public dialogRef: MatDialogRef<WaitlistComponent>,
                public translate: TranslateService,
                private userService: UserService,
                private logger: LoggerService,
                private zone: NgZone,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.inMerchantWaitlist = data.inMerchantWaitlist;
    }


    WaitlistSubscribe() {
        this.userService.subscribeMerchant().subscribe(
            (subscribeMerachant) => {
                this.dialogRef.close(subscribeMerachant.status);
            },
            (error) => {
                this.logger.error(error);
            });
    }

}
