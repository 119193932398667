import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CategoryService} from '../../../services/category.service';
import {MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-category-menu',
    templateUrl: 'category-menu.component.html',
    styleUrls: ['category-menu.component.css']
})

export class CategoryMenuComponent implements OnInit {

    @Input()
    type: string;

    @Input()
    categoryId: string;

    categories: any[];
    @Output() category: EventEmitter<string> = new EventEmitter();

    constructor(public translate: TranslateService,
                private categoryService: CategoryService,
                public snackBar: MatSnackBar) {

    }

    ngOnInit() {
        this.getAllCategories();
    }

    getAllCategories() {
        this.categoryService.all(1, 100, this.type)
            .subscribe((result) => {
                    this.categories = [{name: this.translate.instant('ALL'), id: 'any'}];
                    this.categories = this.categories.concat(result.result.items);
                },
                (err) => {
                    this.snackBar.open(err, this.translate.instant('CLOSE'), {duration: 3000});
                });
    }

    selectCategory(category: any) {
        this.category.emit(category);
    }
}
