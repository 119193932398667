import {Component, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {VoteService} from '../../../services/vote.service';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-vote',
    templateUrl: 'supported-currencies.template.html'
})
export class SupportedCurrenciesComponent implements OnInit {
    env: any = environment;

    page: number = 1;
    itemsPerPage: number = 15;
    status: string = 'APPROVED';
    supportedCurrencies: any[any];
    totalSupportedCurrenciesCount: number;

    constructor(public snackBar: MatSnackBar,
                private route: ActivatedRoute,
                private router: Router,
                public translate: TranslateService,
                private voteService: VoteService) {
    }

    ngOnInit() {
        this.getSupportedCurrencies();
    }

    getSupportedCurrencies() {
        this.voteService.getCurrencies(this.page, this.itemsPerPage, this.status)
            .subscribe(
                (result) => {
                    if (this.page !== result.result.page) {
                        return;
                    }
                    this.supportedCurrencies = result.result.items;
                    this.totalSupportedCurrenciesCount = result.result.total;
                },
                (err) => {
                    this.snackBar.open(err, this.translate.instant('CLOSE'), {duration: 3000});
                });
    }

    prev() {
    }

    next() {
    }
}
