import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../../services/auth.service';
import {MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from '../../../../services/storage.service';
import {ConfigurationService} from '../../../../services/configuration.service';
import {BaseLayoutComponent} from '../base-layout/base-layout.component';
import {UserService} from '../../../../services/user.service';
import { environment } from '../../../../../environments/environment';
import {isNullOrUndefined} from "util";

@Component({
    selector: 'app-payment-layout',
    templateUrl: './payment-layout.template.html'
})
export class PaymentLayoutComponent extends BaseLayoutComponent implements OnInit {
    env: any = environment;
    currentLang: string = '';
    name: string;

    constructor(protected router: Router,
                public route: ActivatedRoute,
                public translate: TranslateService,
                protected authService: AuthService,
                protected storage: StorageService,
                public snackBar: MatSnackBar,
                protected configuration: ConfigurationService,
                protected userService: UserService) {
        super(router, route, translate, storage, configuration, authService);
    }

    ngOnInit() {
        super.ngOnInit();

        if (localStorage.getItem('lang')) {
            this.currentLang = localStorage.getItem('lang');
        } else if (this.translate.getBrowserLang()) {
            const langCandidate = this.translate.getBrowserLang();
            if (this.env.supportedLangs.map(langObj => langObj.ISOCode).includes(langCandidate)) {
                this.currentLang = langCandidate;
            } else {
                this.currentLang = 'en';
            }
        } else {
            this.currentLang = 'en';
        }
        this.translate.use(this.currentLang);

        if (this.isAuthenticated) {
            this.userService.getCurrentUser().subscribe(
                user => {
                    this.name = `${user.firstName} ${user.lastName}`;
                }
            );
        }
    }
}
