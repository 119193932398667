import {Injectable} from '@angular/core';
import {HttpClient, HttpEventType, HttpRequest, HttpResponse} from '@angular/common/http';
import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../environments/environment';


@Injectable()
export class UploadService {
    env: any = environment;

    constructor(private http: HttpClient) {
    }

    public upload(uploadType: string, files: Set<File>, callback: any): { [key: string]: Observable<number> } {
        // this will be the our resulting map
        const status = {};

        files.forEach(file => {
            // create a new multipart-form for every file
            const formData: FormData = new FormData();
            formData.append('file', file, file.name);

            // create a http-post request and pass the form
            // tell it to report the upload progress
            const req = new HttpRequest('POST', `${this.env.uploadHost}/api/upload/${uploadType}`, formData, {
                reportProgress: true
            });

            // create a new progress-subject for every file
            const progress = new Subject<number>();

            // send the http-request and subscribe for progress-updates

            this.http.request(req).subscribe(event => {
                    if (event.type === HttpEventType.UploadProgress) {
                        // calculate the progress percentage
                        const percentDone = Math.round(100 * event.loaded / event.total);

                        // pass the percentage into the progress-stream
                        progress.next(percentDone);
                    } else if (event instanceof HttpResponse) {
                        const resBody: any = event.body;
                        callback(null, resBody.result);

                        // Close the progress-stream if we get an answer form the API
                        // The upload is complete
                        progress.complete();
                    }
                },
                err => {
                    callback(err.message);
                    progress.complete();
                }
            );

            // Save every progress-observable in a map of all observables
            status[file.name] = {
                progress: progress.asObservable()
            };
        });

        // return the map of progress.observables
        return status;
    }
}
