import {AfterViewInit, Component, ElementRef} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from '../../../services/storage.service';
import {BaseHeaderComponent} from '../layouts/base-header/base-header.component';
import {UserService} from '../../../services/user.service';

import {MatSnackBar} from '@angular/material';

@Component({
    selector: 'app-user-header',
    templateUrl: './user-header.template.html'
})

export class UserHeaderComponent extends BaseHeaderComponent implements AfterViewInit {
    merchantEnrollment: string;

    constructor(public translate: TranslateService,
                protected router: Router,
                protected storage: StorageService,
                protected element: ElementRef,
                private userService: UserService,
                public snackBar: MatSnackBar) {
        super(storage, router, element, translate);
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();

        this.userService.getCurrentUser()
            .subscribe(
                (user) => {
                    this.merchantEnrollment = user.merchantEnrollment;
                },
                (err) => {
                    this.snackBar.open(err, this.translate.instant('CLOSE'), {duration: 3000});
                });
    }
}
