import {Injectable} from '@angular/core';
import {NetworkHelper} from '../helpers/network.helper';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../environments/environment';

@Injectable()
export class MiscService {
    private readonly hostUrl: string;

    constructor(private networkHelper: NetworkHelper) {
        this.hostUrl = environment.host;
    }

    getCountries(): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/misc/countries`);
    }

    getVats(): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/misc/vats`);
    }

    getLocationByIp(): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/location/geo-data`);
    }
}


