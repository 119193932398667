import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-upsell-confirm-alert',
    templateUrl: 'upsell-confirm-alert.component.html'
})
export class UpsellConfirmAlertComponent implements OnInit {
    public headerText: string;
    public bodyText: string;
    public confirmButtonName: string;

    constructor(public translate: TranslateService, public dialogRef: MatDialogRef<UpsellConfirmAlertComponent>) {

    }

    ngOnInit() {
        if (!this.confirmButtonName) {
            this.confirmButtonName = this.translate.instant('CONFIRM');
        }
    }

    onAcceptRemove(accept: boolean) {
        this.dialogRef.close(accept);
    }
}
