import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {NetworkHelper} from '../helpers/network.helper';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class ContactMessageService {
    private readonly hostUrl: string;

    constructor(private router: Router, private networkHelper: NetworkHelper) {
        this.hostUrl = environment.host;
    }

    send(email: string, name: string, subject: string, message: string, captcha: string): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/contact-message/add`,
            {email: email, name: name, subject: subject, message: message, captcha: captcha}
        );
    }

    all(page: number, itemsPerPage: number, status: string, keyword?: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/contact-message/all/${page}/${itemsPerPage}/${status}/${keyword}`, {});
    }

    changeStatus(id: string, status: string): Observable<any> {
        return this.networkHelper.patch(`${this.hostUrl}/contact-message/change-status/${id}/${status}`, {});
    }
}
