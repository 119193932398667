import {Component, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {VoteService} from '../../../services/vote.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-vote',
    templateUrl: 'votes.template.html'
})
export class VotesComponent implements OnInit {
    page: number = 1;
    itemsPerPage: number = 15;
    status: string = 'NEW';
    supportedCurrencies: any[any];
    totalSupportedCurrenciesCount: number;
    votedCurrencies: [any];

    constructor(public snackBar: MatSnackBar,
                private route: ActivatedRoute,
                private router: Router,
                public translate: TranslateService,
                private voteService: VoteService) {
    }

    ngOnInit() {
        this.getSupportedCurrencies();
    }

    getSupportedCurrencies() {
        this.voteService.getCurrencies(this.page, this.itemsPerPage, this.status)
            .subscribe(
                (result) => {
                    if (this.page !== result.result.page) {
                        return;
                    }

                    this.totalSupportedCurrenciesCount = result.result.total;

                    this.getVotedCurrencies(result.result.items);
                },
                (err) => {
                    this.snackBar.open(err, this.translate.instant('CLOSE'), {duration: 3000});
                });
    }

    getVotedCurrencies(votes: any) {
        this.voteService.getVotedCurrencies()
            .subscribe(
                (result) => {
                    this.votedCurrencies = result.result;

                    for (const v of votes) {
                        v.voted = false;
                        if(this.votedCurrencies.includes(v.id.toString())) {
                            v.voted = true;
                        }
                    }

                    this.supportedCurrencies = votes;
                },
                (err) => {
                    this.snackBar.open(err, this.translate.instant('CLOSE'), {duration: 3000});
                });
    }

    voteForCurrency(currencyId) {
        this.voteService.voteForCurrency(currencyId)
            .subscribe(
                (result) => {
                    this.getSupportedCurrencies();
                },
                (err) => {
                    this.snackBar.open(err, this.translate.instant('CLOSE'), {duration: 3000});
                });

        return;
    }
}
