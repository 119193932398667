import {Injectable} from '@angular/core';
import {NetworkHelper} from '../helpers/network.helper';
import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {environment} from '../../environments/environment';
import {StorageService} from './storage.service';

@Injectable()
export class UserService {
    static emptyUser: any = {allowedAccessIPs: ['']}; // to fix mat-choice-list placeholder bug
    currentUser: BehaviorSubject<any> = null;
    private readonly hostUrl: string;
    private currentUserChanged: boolean = true;
    private firstLoad: boolean = true;

    constructor(private networkHelper: NetworkHelper, private storage: StorageService) {
        this.hostUrl = environment.host;
    }

    getCurrentUser(): BehaviorSubject<any> {
        if (!this.currentUserChanged) {
            return this.currentUser;
        }

        if (this.firstLoad) {
            this.firstLoad = false;
            this.currentUser = new BehaviorSubject<any>(UserService.emptyUser);
        }

        if (this.currentUserChanged) {
            this.currentUserChanged = false;

            const userId = this.storage.getItem('userId');
            this.get(userId)
                .subscribe((user) => {
                        this.currentUser.next(user.result);
                    },
                    (error) => {
                        this.currentUser.error(error); // propagate up
                    });
        }

        return this.currentUser;
    }

    get(userId: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/user/${userId}`, {});
    }

    deactivateAccount(userId: string, explainMessage: string, userPassword?: string): Observable<any> {
        return this.networkHelper
            .patch(`${this.hostUrl}/user/deactivate/${userId}`,
                {userPassword: userPassword, explainMessage: explainMessage});
    }

    activateInactiveAccount(userId: string, explainMessage: string): Observable<any> {
        return this.networkHelper.patch(`${this.hostUrl}/user/activate/${userId}`, {explainMessage: explainMessage});
    }

    updateUser(userId: string, data: any): Observable<any> {
        this.currentUserChanged = true;

        return this.networkHelper.patch(`${this.hostUrl}/user/${userId}`, data);
    }

    updateMerchant(id: string, merchant: any): Observable<any> {
        this.currentUserChanged = true;

        return this.networkHelper.put(`${this.hostUrl}/merchant/${id}`, merchant);
    }

    changeJustRegistered(): Observable<any> {
        this.currentUserChanged = true;

        return this.networkHelper.put(`${this.hostUrl}/user/change-just-registered`, {});
    }

    all(page: number, itemsPerPage: number, status: string, role: string, keyword?: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/user/all/${page}/${itemsPerPage}/${status}/${role}/${keyword}`, {});
    }

    subscribeMerchant(): Observable<any> {
        return this.networkHelper.patch(`${this.hostUrl}/user/waitlist`, {});
    }

    getDirectPartners(page: number, itemsPerPage: number, userId: string, startDate: number, endDate: number): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/user/all-partners/${page}/${itemsPerPage}/${userId}/${startDate}/${endDate}`, {});
    }

    changeKyc(status: string): Observable<any> {
        this.currentUserChanged = true;
        return this.networkHelper.patch(`${this.hostUrl}/user/change-kyc/${status}`, {});
    }

    getProfileCompleteness(userId: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/user/profile-completeness/${userId}`, {});
    }
}
