import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-pie-chart',
    templateUrl: 'pie-chart.component.html'
})

export class PieChartComponent implements OnInit {
    @Input()
    chartData: any[];

    @Input()
    chartLabels: Date[];

    @Input()
    currency: string;

    chartIsReady = false;

    chartOptions: any;

    chartColors: any[] = [{
        backgroundColor: ['#37bced', '#ccf4fe'],
        borderColor: 'rgb(28,146,190)',
        borderWidth: 1
    }];

    ngOnInit() {
        this.chartOptions = {
            responsive: true,
            tooltips: {
                mode: 'index',
                intersect: false,
                callbacks: {
                    title: (tooltipItem, data) => {
                        return data['labels'][tooltipItem[0]['index']];
                    },
                    label: (tooltipItem, data) => {
                        return parseFloat(data['datasets'][0]['data'][tooltipItem['index']]).toString() + ' ' + this.currency;
                    },
                }
            },
        };

        this.chartIsReady = true;
    }
}
