import {Injectable} from '@angular/core';
import {NetworkHelper} from '../helpers/network.helper';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../environments/environment';

@Injectable()
export class MerchantService {
    private readonly hostUrl: string;

    constructor(private networkHelper: NetworkHelper) {
        this.hostUrl = environment.host;
    }

    add(merchant: any): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/merchant`, merchant);
    }

    updatePayout(userId, data): Observable<any> {
        return this.networkHelper.put(`${this.hostUrl}/merchant/payout/${userId}`, data);
    }

    updateOrderForm(userId, data): Observable<any> {
        return this.networkHelper.put(`${this.hostUrl}/merchant/order-form/${userId}/${data}`, {});
    }

    updateRefundPolicy(userId, data): Observable<any> {
        return this.networkHelper.put(`${this.hostUrl}/merchant/refund-policy/${userId}`, data);
    }

    enrollments(page: number, itemsPerPage: number, enrollmentStatus: string, keyword?: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/merchant/enrollments/${page}/${itemsPerPage}/${enrollmentStatus}/${keyword}`, {});
    }

    declineEnrollment(userId: string, explainMessage: string, userPassword?: string): Observable<any> {
        return this.networkHelper
            .patch(`${this.hostUrl}/merchant/decline-enrollment/${userId}`, {userPassword: userPassword, explainMessage: explainMessage});
    }

    approveEnrollment(userId: string, explainMessage: string): Observable<any> {
        return this.networkHelper.patch(`${this.hostUrl}/merchant/approve-enrollment/${userId}`, {explainMessage: explainMessage});
    }
}
