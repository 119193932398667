import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import * as json2csv from 'json2csv';

@Injectable()
export class Utl {
    static get noImage(): string {
        return `${environment.yourmarketUrl}/${environment.noImagePlaceholder}`;
    }

    static isValidEmail(email): boolean {
        return (email && email.match(/[a-z0-9!#$%&'*+\/=?\^_`{|}~\-]+(?:\.[a-z0-9!#$%&'*+\/=?\^_`{|}~\-]+)*@(?:[a-z0-9](?:[a-z0-9\-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9\-]*[a-z0-9])?/i));
    }

    static cloneArray(arr): any[] {
        return arr.slice(0);
    }

    static exportHandler(result: any, fileName: string) {
        const blob = new Blob([result]);
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, fileName);
        } else {
            const a = window.document.createElement('a');
            a['href'] = window.URL.createObjectURL(blob);
            a['download'] = fileName;
            document.body.appendChild(a);
            // IE: "Access is denied";
            // see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
            a.click();
            document.body.removeChild(a);
        }
    }

    static exportToCsv(data: any, fileName: string) {
        const res = json2csv.parse(data);
        Utl.exportHandler(res, fileName);
    }

    static removeZeros(number: number, fractionDigit?: number): number {
        let num;
        if (fractionDigit >= 0) {
            num = number.toFixed(fractionDigit);
        }
        return Number(num.toString());
    }

    static findElemInArray(array, name, value) {
        let search;
        for (const item of array) {
            if (item.hasOwnProperty(name) && item[name] === value) {
                search = item;
                break;
            }
        }

        return search;
    }

    static removeItemsFromArray(arr: any[], removed: any[], byValue = null): any[] {
        let what;
        let length = removed.length;
        let deletedItem;

        while (length && arr.length) {
            what = removed[--length];
            if (byValue) {
                for (let a = 0; a < arr.length; ++a) {
                    if (arr[a][byValue] === what[byValue]) {
                        arr.splice(a, 1);
                        break;
                    }
                }
            } else {
                while ((deletedItem = arr.indexOf(what)) !== -1) {
                    arr.splice(deletedItem, 1);
                }
            }
        }
        return arr;
    }

    static formatFixed(value, precisison) {
        if (typeof value === 'string') {
            value = parseFloat(value);
        }
        if (typeof value !== 'number') {
            return null;
        }

        return value.toFixed(precisison);
    }
}
