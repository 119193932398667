import {Component, Input, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material';

import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from '../../../services/storage.service';

@Component({
    selector: 'navigation',
    templateUrl: './navigation.template.html'
})
export class NavigationComponent implements OnInit {
    env: any = environment;
    showNavDashboard = false;
    @Input() currentActive = 0;

    constructor(public translate: TranslateService,
                public snackBar: MatSnackBar,
                private router: Router,
                public storage: StorageService) {

    }

    ngOnInit() {

    }


}
