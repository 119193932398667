import {Injectable} from '@angular/core';
import {NetworkHelper} from '../helpers/network.helper';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../environments/environment';
import {StorageService} from './storage.service';

@Injectable()
export class SubscriptionService {
    private readonly hostUrl: string;

    constructor(private networkHelper: NetworkHelper,
                private storage: StorageService) {
        this.hostUrl = environment.host;
    }

    getTire(id): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/subscription/${id}`, {});
    }

    all(): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/subscription/all`, {});
    }

    getByTireType(tireType: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/subscription/tire-type/${tireType}`, {});
    }

    create(subscriptionData: any): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/subscription/create`, subscriptionData);
    }

    subscribe(subscriptionType: string, currency: string): Observable<any> {
        return this.networkHelper.put(`${this.hostUrl}/subscription/subscribe/${subscriptionType}/${currency}`, {});
    }

    fulfillDemo(invoiceNumber: string): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/subscription/fulfill-demo`, {
            invoiceNumber: invoiceNumber
        });
    }

    getSubscriptionPrice(type: string, currency: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/subscription/subscription-price/${type}/${currency}`, {});
    }
}
