import {Injectable} from '@angular/core';
import {NetworkHelper} from '../helpers/network.helper';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../environments/environment';
import {StorageService} from './storage.service';

@Injectable()
export class IntegrationService {
    private readonly hostUrl: string;

    constructor(private networkHelper: NetworkHelper,
                private storage: StorageService) {
        this.hostUrl = environment.host;
    }

    add(integration: any): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/integration/add`, integration);
    }

    get(id: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/integration/${id}`);
    }

    all(userId: string, productId: string = '', page: number, itemsPerPage: number, sortType: string,
        sortDir: string, type: string, status: string, keyword: string = ''): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/integration/all/${userId}/${productId}/${page}/${itemsPerPage}/${sortType}/${sortDir}/${type}/${status}/${keyword}`);
    }

    edit(id: string, integration: any): Observable<any> {
        return this.networkHelper.patch(`${this.hostUrl}/integration/${id}`, integration);
    }

    delete(id: string): Observable<any> {
        return this.networkHelper.del(`${this.hostUrl}/integration/${id}`, {});
    }

    sendIntegrationData(): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/integration/send-data`, null);
    }
}
