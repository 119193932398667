import {Component, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material';

import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from '../../../services/storage.service';

@Component({
    selector: 'app-footer',
    templateUrl: 'footer.template.html'
})
export class FooterComponent implements OnInit {
    env: any = environment;
    lang: string = 'en';

    constructor(public translate: TranslateService,
                public snackBar: MatSnackBar,
                private router: Router,
                public storage: StorageService) {
        const userLang = localStorage.getItem('lang');
        this.lang = this.env.supportedLangs.map(lang => lang.ISOCode).includes(userLang) ? userLang : 'en';
    }

    ngOnInit() {

    }
}
