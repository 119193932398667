import {Component, Input, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from '../../../services/storage.service';

@Component({
    selector: 'app-language-selector',
    templateUrl: './language-selector.template.html'
})
export class LanguageSelectorComponent implements OnInit {
    env: any = environment;
    @Input()
    set dontShow(data: any[]) {
        if (data && data.length) {
            for (const item of data) {
                if (this.show[item]) {
                    this.show[item] = false;
                }
            }
        }
    }
    languages: any = [];
    currentLang: any = {};
    show: any = {
        name: true,
        image: true,
        code: true
    };

    constructor(public translate: TranslateService,
                public snackBar: MatSnackBar,
                private router: Router,
                public storage: StorageService) {
        const currentLangCode = localStorage.getItem('lang') || this.translate.getBrowserLang() || 'en';
        this.setCurrentLang(currentLangCode);
    }

    ngOnInit() {
        this.initLang();
    }

    initLang() {
        this.languages = [];
        for (const lang of environment.supportedLangs) {
            if (this.currentLang.ISOCode !== lang.ISOCode) {
                this.languages.push(lang);
            }
        }
    }

    selectLang() {
        localStorage.setItem('lang', this.currentLang.ISOCode);
        this.translate.use(this.currentLang.ISOCode);
        this.initLang();
    }

    setCurrentLang(lang: string) {
        for (const sl of this.env.supportedLangs) {
            if (lang === sl.ISOCode) {
                this.currentLang = sl;
                break;
            }
        }
    }

}
