export const environment = {
    production: false,
    host: 'https://market.arag.club/api',
    maintenanceServiceMessage: '',
    appTitle: 'YourMarket',
    authHeaderPrefix: 'ym',
    yourmarketUrl: 'https://market.arag.club',
    landingUrl: 'https://market.arag.club',
    helpUrl: 'http://ym-market.freshdesk.io',
    faqUrl: 'faq',
    blogUrl: 'blog',
    announcementsUrl: '#announcements-section',
    missionUrl: '#mission-section',
    benefitsUrl: '#benefits-section',
    fbShareURL: 'https://www.facebook.com/ym-market.official',
    fbAppID: 'XXXX',
    version: '0.0.3',
    uploadsUrl: 'https://storage.arag.club',
    uploadHost: 'https://storage.arag.club',
    noImagePlaceholder: 'assets/images/no_image_available.png',
    supportedLangs: [
        {
            lang: 'English',
            ISOCode: 'en',
            image: '/assets/images/circle-flags/en.svg'
        },
        {
            lang: 'German',
            ISOCode: 'de',
            image: '/assets/images/circle-flags/de.svg'
        }/*,
        {
            lang: 'Russian',
            ISOCode: 'ru',
            image: '/assets/images/circle-flags/ru.svg'
        }*/
    ],
    integrationEvents: [
        {
            name: 'ON_REFUND',
            value: 'ON_REFUND'
        },
        {
            name: 'ON_FULFILL',
            value: 'ON_FULFILL'
        },
        {
            name: 'ON_PAYMENT_FAILED',
            value: 'ON_PAYMENT_FAILED'
        }
    ],
    showRememberMeOnLogin: false,
    siteKeyV2: '6LfejnoUAAAAAPVp6odY2SH_dfKKoTAnZSTuUPLG',
    siteKeyV3: '6LepnZUUAAAAADqI5hUXvOjXxEpQT1cYinb_c4Av'
};
