import {Component, Input, ViewChild} from '@angular/core';
import {MatDialogRef, MatSnackBar} from '@angular/material';
import {forkJoin} from 'rxjs/observable/forkJoin';
import {UploadService} from '../../../services/upload.service';
import {environment} from '../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-upload-dialog',
    templateUrl: 'upload-dialog.component.html'
})
export class UploadDialogComponent {
    progress;
    canBeClosed = true;
    primaryButtonText = 'Upload';
    showCancelButton = true;
    uploading = false;
    uploadSuccessful = false;
    @ViewChild('file', {static: true}) file;
    public files: Set<File> = new Set();
    public addImagePath: any[] = [];
    env: any = environment;
    @Input() public entityType: string = '';
    @Input() public dim: number = 50;
    @Input() public imagesCount: number = 0;

    constructor(public translate: TranslateService,
                private snackBar: MatSnackBar,
                public dialogRef: MatDialogRef<UploadDialogComponent>,
                public uploadService: UploadService) {
        this.primaryButtonText = this.translate.instant('UPLOAD');
    }

    addFiles() {
        this.file.nativeElement.click();
    }

    onFilesAdded() {
        const files: { [key: string]: File } = this.file.nativeElement.files;
        for (const key in files) {
            if (!isNaN(parseInt(key, 10))) {
                if (this.files.size < this.imagesCount) {
                    if (['image/gif', 'image/jpeg', 'image/png'].includes(files[key].type)) {
                        this.files.add(files[key]);
                    } else {
                        this.snackBar.open(this.translate.instant('PRODUCT_STUFF.UPLOAD.FILE_TYPE_ERROR'),
                            this.translate.instant('CLOSE'), {duration: 3000});
                    }
                } else {
                    this.snackBar
                        .open(this.translate.instant('PRODUCT_STUFF.UPLOAD.MAXIMUM_TRESHOLD', {count: this.imagesCount}),
                            this.translate.instant('CLOSE'),
                            {duration: 3000});
                    break;
                }
            }
        }
    }

    closeDialog() {
        // if everything was uploaded already, just close the dialog
        if (this.uploadSuccessful) {
            return this.dialogRef.close(this.addImagePath);
        }

        // set the component state to "uploading"
        this.uploading = true;
        this.addImagePath = [];

        // start the upload and save the progress map
        const progress = this.uploadService.upload('product', this.files, (error, result) => {
            if (error) {
                this.snackBar.open(error, this.translate.instant('CLOSE'), {duration: 3000});
            } else {
                this.progress = progress;
                const imgData = {
                    dir: result.dir,
                    name: result.name
                };

                this.addImagePath.push(imgData);
            }
        });

        // convert the progress map into an array
        const allProgressObservables: any = [];
        for (const key in progress) {
            if (progress.hasOwnProperty(key)) {
                allProgressObservables.push(progress[key]['progress']);
            }
        }

        // Adjust the state variables

        // The OK-button should have the text "Finish" now
        this.primaryButtonText = this.translate.instant('FINISH');

        // The dialog should not be closed while uploading
        this.canBeClosed = false;
        this.dialogRef.disableClose = true;

        // Hide the cancel-button
        this.showCancelButton = false;

        // When all progress-observables are completed...
        forkJoin(allProgressObservables).subscribe(end => {
                // ... the dialog can be closed again...
                this.canBeClosed = true;
                this.dialogRef.disableClose = false;

                // ... the upload was successful...
                this.uploadSuccessful = true;

                // ... and the component is no longer uploading
                this.uploading = false;
            });
    }

    onRemove(index) {
        this.addImagePath.splice(index, 1);
    }
}
