import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'removeBullets'})

export class RemoveBullets implements PipeTransform {
    transform(givenStr: string): string {
        if (givenStr) {
            const givenStrArr = givenStr.split('*');
            return givenStrArr[0].trim();
        }
        return;
    }
}
