import {Component, NgZone, OnInit} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../environments/environment';
import {AuthService} from '../../../services/auth.service';


@Component({
    selector: 'app-info',
    templateUrl: 'settings.component.html'
})
export class SettingsComponent implements OnInit {
    user: any;
    role: string;

    env: any = environment;
    urlBase: string = '/user/settings/';
    selectionsTabs: any[any] = [
        {
            endpoint: 'info',
            text: 'APP_SELECTION_TABS.PROFILE_SETTINGS.SETTINGS.PERSONAL'
        },
        // {
        //     endpoint: 'support-contact',
        //     text: 'APP_SELECTION_TABS.PROFILE_SETTINGS.SETTINGS.SUPPORT_CONTACT',
        //     active: false
        // },
        {
            endpoint: 'payment',
            text: 'APP_SELECTION_TABS.PROFILE_SETTINGS.SETTINGS.PAYMENT'
        },
        {
            endpoint: 'advanced',
            text: 'APP_SELECTION_TABS.PROFILE_SETTINGS.SETTINGS.ADVANCED'
        },
        /*{
            endpoint: 'subscriptions',
            text: 'APP_SELECTION_TABS.PROFILE_SETTINGS.SETTINGS.SUBSCRIBE'
        },*/
        {
            endpoint: 'kyc',
            text: 'APP_SELECTION_TABS.PROFILE_SETTINGS.SETTINGS.KYC'
        },
        {
            endpoint: 'integration',
            text: 'APP_SELECTION_TABS.PROFILE_SETTINGS.SETTINGS.INTEGRATION'
        },
        {
            endpoint: 'test',
            text: 'APP_SELECTION_TABS.PROFILE_SETTINGS.SETTINGS.TEST'
        }
    ];
    selectedText: string = this.selectionsTabs[0]['text'];
    selectedTab: string = this.selectionsTabs[0]['endpoint'];
    selectionRole: string = 'router';

    constructor(private ngZone: NgZone,
                private authService: AuthService,
                private userService: UserService,
                public  snackBar: MatSnackBar,
                public  translate: TranslateService) {

        this.getProfile();
    }

    ngOnInit() {
    }

    getProfile() {
        this.userService.getCurrentUser()
            .subscribe(
                (user) => {
                    this.user = user;
                    if (user.role === 'user') {
                        this.selectionsTabs = [
                            {
                                endpoint: 'info',
                                text: 'APP_SELECTION_TABS.PROFILE_SETTINGS.SETTINGS.PERSONAL'
                            },
                            {
                                endpoint: 'payment',
                                text: 'APP_SELECTION_TABS.PROFILE_SETTINGS.SETTINGS.PAYMENT'
                            },
                            {
                                endpoint: 'advanced',
                                text: 'APP_SELECTION_TABS.PROFILE_SETTINGS.SETTINGS.ADVANCED'
                            }
                        ];
                    }

                },
                (err) => {
                    this.snackBar.open(err, this.translate.instant('CLOSE'), {duration: 3000});
                });
    }

    onRouteSelect(selectedTab: any) {
        this.selectedTab = selectedTab;
    }
}
