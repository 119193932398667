import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatDialog} from '@angular/material';
import {UploadDialogComponent} from './upload-dialog.component';
import {environment} from '../../../../environments/environment';


@Component({
    selector: 'app-upload',
    templateUrl: './upload.component.html'
})
export class UploadComponent {
    env: any = environment;
    @Input() public imageUrls: any[] = [];
    @Input() public entityType: string = '';
    @Input() public imagesCount: number = 10;
    @Input() public dim: number = 50;
    @Output() files: EventEmitter<string[]> = new EventEmitter();

    constructor(public dialog: MatDialog) {
    }

    public openUploadDialog() {
        const dialogRef = this.dialog.open(UploadDialogComponent,
            {width: '50%', height: '50%', panelClass: 'uploader-panel'});
        dialogRef.componentInstance.entityType = this.entityType;
        dialogRef.componentInstance.imagesCount = this.imagesCount;
        dialogRef.componentInstance.dim = this.dim;

        dialogRef.afterClosed().subscribe((result) => {
            if (result instanceof Array) {
                this.files.emit(result);
            }
        });
    }

    public onRemove(index) {
        this.imageUrls.splice(index, 1);
    }

    public onPrimary(index) {
        if (index !== 0) {
            const item = this.imageUrls.splice(index, 1);
            this.imageUrls.unshift(...item);
        }
    }
}
