import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-rating-bar',
    templateUrl: 'rating.component.html',
    styleUrls: ['rating.component.css']
})

export class RatingComponent implements OnInit {
    @Input() maxRate: number;
    @Input() type: string;
    @Input() fontSize = 17;
    @Output() rate: EventEmitter<number> = new EventEmitter();
    stars: any[];
    private rating_: number;

    get rating(): number {
        return this.rating_;
    }

    @Input() set rating(rate: number) {
        this.rating_ = rate;
        this.initialize();
    }

    ngOnInit() {
        this.initialize();
    }

    initialize() {
        this.stars = [];
        for (let i = 1; i <= this.maxRate; i++) {
            const starInfo = {
                type: null,
                percent: 0
            };
            starInfo.type = 'empty';
            if (i <= this.rating_) {
                starInfo.type = 'full';
            }
            if (this.rating_ > i - 1 && this.rating_ < i) {
                starInfo.type = 'partial';
                starInfo.percent = this.getFloatPart(this.rating_);
            }
            this.stars.push(starInfo);
        }
        this.stars.reverse();
    }

    rateFromUser(rating) {
        this.rating_ = rating;
        this.stars = [];
        for (let i = 1; i <= this.maxRate; i++) {
            const starInfo = {
                type: null,
                percent: 0
            };
            starInfo.type = 'empty';
            if (i <= this.rating_) {
                starInfo.type = 'full';
            }
            if (this.rating_ > i - 1 && this.rating_ < i) {
                starInfo.type = 'partial';
                starInfo.percent = this.getFloatPart(this.rating_);
            }
            this.stars.push(starInfo);
        }
        this.stars.reverse();
        this.rate.emit(rating);

    }

    ratingClass() {
        if (this.rating_) {
            return 'type-rate-rated';
        }
        return 'type-rate';
    }

    getFloatPart(number) {
        return Number((number % 1).toFixed(1)) * 100;
    }
}
