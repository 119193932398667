import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {NetworkHelper} from '../helpers/network.helper';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class ICOService {
    private readonly hostUrl: string;

    constructor(private router: Router, private networkHelper: NetworkHelper) {
        this.hostUrl = environment.host;
    }

    join(userData): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/user/join-ico`, userData);
    }
}
