import {MatDialogRef, MatSnackBar, MAT_DIALOG_DATA} from '@angular/material';
import {Component, Inject} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from '../../../services/storage.service';
import {MerchantService} from '../../../services/merchant.service';
import {isNullOrUndefined} from 'util';


@Component({
    selector: 'app-merchant-enrollment',
    templateUrl: './merchant-enrollment.template.html'
})
export class MerchantEnrollmentComponent {
    action: string;
    userId: string;
    explainMessage: string = '';
    ownerId: string = '';

    constructor(public dialogRef: MatDialogRef<MerchantEnrollmentComponent>,
                public translate: TranslateService,
                private storage: StorageService,
                private snackBar: MatSnackBar,
                private merchantService: MerchantService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.ownerId = this.storage.getItem('userId');
        this.userId = data.userId;
        this.action = data.action;
    }

    decline() {
        if (isNullOrUndefined(this.explainMessage) || this.explainMessage !== '') {
            this.merchantService.declineEnrollment(this.userId, this.explainMessage)
                .subscribe(
                    (result) => {
                        this.snackBar.open(this.translate.instant('MERCHANT_STUFF.MERCHANT_SETTINGS.SUCCESSFULLY_DECLINED'),
                            this.translate.instant('CLOSE'), {duration: 3000});
                        this.dialogRef.close({success: true});
                    },
                    (err) => {
                        this.snackBar.open(err, this.translate.instant('CLOSE'), {duration: 3000});
                    });
            return;
        }
        this.snackBar.open(this.translate.instant('MERCHANT_STUFF.MERCHANT_ENROLLMENT.ERROR'),
            this.translate.instant('CLOSE'), {duration: 3000});
    }

    approve() {
        if (!isNullOrUndefined(this.explainMessage) && this.explainMessage !== '') {
            this.merchantService.approveEnrollment(this.userId, this.explainMessage)
                .subscribe(
                    (result) => {
                        this.snackBar.open(this.translate.instant('MERCHANT_STUFF.MERCHANT_SETTINGS.SUCCESSFULLY_APPROVED'),
                            this.translate.instant('CLOSE'), {duration: 3000});
                        this.dialogRef.close({success: true});
                    },
                    (err) => {
                        this.snackBar.open(err, this.translate.instant('CLOSE'), {duration: 3000});
                        this.dialogRef.close();
                    });
            return;
        }
        this.snackBar.open(this.translate.instant('MERCHANT_STUFF.MERCHANT_ENROLLMENT.ERROR'),
            this.translate.instant('CLOSE'), {duration: 3000});
    }

    merchantEnrollment() {
        if (this.action === 'approve') {
            this.approve();
        } else {
            this.decline();
        }
    }
}
