import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {isNullOrUndefined} from 'util';
import {StorageService} from '../../../../services/storage.service';
import {ConfigurationService} from '../../../../services/configuration.service';
import {Subscription} from 'rxjs/Subscription';
import {AuthService} from '../../../../services/auth.service';
import {environment} from '../../../../../environments/environment';
import {AppUtl} from '../../../../helpers/app-utl';

@Component({
    selector: 'app-base-layout',
    template: ''
})
export class BaseLayoutComponent implements OnInit, OnDestroy {
    env: any = environment; // used in the children
    maintenanceStatus: boolean = false;
    maintenanceMessage: string = '';
    currentYear: number;
    isAuthenticated = true;

    private routeSubscription: Subscription;

    constructor(protected router: Router,
                public route: ActivatedRoute,
                public translate: TranslateService,
                protected storage: StorageService,
                protected configuration: ConfigurationService,
                protected authService: AuthService) {
        this.routeSubscription = router.events.filter(event => event instanceof NavigationEnd)
            .subscribe((routeChange: NavigationEnd) => {
                this.checkForMaintenance();
            });

        this.route.queryParams.subscribe(params => {
            if (params['ref']) {
                this.storage.setStorage(localStorage);
                if (params['ref']) {
                    localStorage.setItem('refToken', params['ref']);
                }
            }
        });

        this.currentYear = AppUtl.getCurrentYear();
    }

    static redirectToMaintenancePage(router) {
        return (!router.includes('maintenance') && !router.includes('signin') && !router.includes('contact-us'));
    }

    ngOnInit() {
        this.isAuthenticated = this.storage.getItem('isLoggedin') === 'true';
    }

    ngOnDestroy() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
    }

    checkForMaintenance() {
        const lang: string = localStorage.getItem('lang');
        this.configuration.getMaintenance().subscribe(
            (result) => {
                if (result.result.maintenance === false) {
                    return;
                }

                this.configuration.setMessage(result.result.maintenanceMessage);

                if (this.storage.getItem('token')) { // if user is logged in
                    if (result.result.maintenanceStatus === 'notification') {
                        this.maintenanceStatus = true;
                        this.maintenanceMessage = result.result.maintenanceMessage;
                    }

                    if (result.result.maintenanceStatus === 'active') {
                        this.maintenanceLogOut();
                        return;
                    }
                } else {
                    if (result.result.maintenanceStatus === 'notification') {
                        this.maintenanceStatus = true;
                        this.maintenanceMessage = result.result.maintenanceMessage;
                    }

                    if (result.result.maintenanceStatus === 'active') {
                        if (BaseLayoutComponent.redirectToMaintenancePage(this.router.url)) {
                            this.maintenanceStatus = false;
                            this.maintenanceMessage = '';

                            if (isNullOrUndefined(lang)) {
                                this.router.navigateByUrl(`/sessions/maintenance`);
                            } else {
                                this.router.navigateByUrl(`/${lang}/sessions/maintenance`);
                            }
                            return;
                        } else {
                            this.maintenanceStatus = true;
                            this.maintenanceMessage = result.result.maintenanceMessage;
                        }
                    }
                }
            },
            (err) => {
            });
    }

    maintenanceLogOut() {
        const lang: string = localStorage.getItem('lang');
        this.authService.logout()
            .subscribe((result) => {
                    if (isNullOrUndefined(lang)) {
                        this.router.navigateByUrl(`/sessions/maintenance`);
                    } else {
                        this.router.navigateByUrl(`/${lang}/sessions/maintenance`);
                    }
                    return;
                },
                (err) => {
                });
    }
}
