import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-confirm-alert',
    templateUrl: 'confirm-alert.component.html'
})
export class ConfirmAlertComponent implements OnInit {
    public headerText: string;
    public bodyText: string;
    public confirmButtonName: string;

    constructor(public translate: TranslateService, private dialogRef: MatDialogRef<ConfirmAlertComponent>) {

    }

    ngOnInit() {
        if (!this.confirmButtonName) {
            this.confirmButtonName = this.translate.instant('CONFIRM');
        }
    }

    onAcceptRemove(accept: boolean) {
        this.dialogRef.close(accept);
    }
}
