import {Injectable} from '@angular/core';
import {NetworkHelper} from '../helpers/network.helper';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../environments/environment';
import {StorageService} from './storage.service';

@Injectable()
export class CustomerCenterService {
    private readonly hostUrl: string;

    constructor(private networkHelper: NetworkHelper, private storage: StorageService) {
        this.hostUrl = environment.host;
    }

    allDisputes(page: number, itemsPerPage: number, userId: string, status: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/customer-center/all/${page}/${itemsPerPage}/${userId}/${status}`, {});
    }

    disputeStats(userId: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/customer-center/get-dispute-stats/${userId}`, {});
    }

    addDispute(orderId: string, productId: string, message: string): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/customer-center/add`, {
            orderId: orderId,
            productId: productId,
            message: message
        });
    }

    allFeedback(page: number, itemsPerPage: number, userId: string, clientId: string, productId: string, rating: string, sortType: string, sortDir: string, startDate?: number, endDate?: number, keyword?: string): Observable<any> {
        let url = `${this.hostUrl}/customer-center/all-feedbacks/${page}/${itemsPerPage}/${userId}/${clientId}/${productId}/${rating}/${sortType}/${sortDir}`;
        if (startDate && endDate) {
            url += `/${startDate}/${endDate}`;
        }
        if (keyword) {
            url += `${keyword}`;
        }
        return this.networkHelper.get(url, {});
    }

    ratingStats(userId: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/customer-center/get-rating/${userId}`, {});
    }

    getFeedback(userId: string, orderId: string, productId: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/customer-center/feedback/${userId}/${orderId}/${productId}`, {});
    }

    addFeedback(orderId: string, productId: string, message: string, rate: number): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/customer-center/add-feedback`, {
            orderId: orderId,
            productId: productId,
            message: message,
            rate: rate
        });
    }

    updateFeedback(feedbackId: string, message: string, rate: number): Observable<any> {
        return this.networkHelper.patch(`${this.hostUrl}/customer-center/update-feedback/${feedbackId}`, {message: message, rate: rate});
    }

    removeFeedback(id: string): Observable<any> {
        return this.networkHelper.del(`${this.hostUrl}/customer-center/remove-feedback/${id}`, {});
    }

    refund(disputeId: string): Observable<any> {
        return this.networkHelper.patch(`${this.hostUrl}/customer-center/refund/${disputeId}`, {});
    }
}
