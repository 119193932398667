import {Injectable} from '@angular/core';
import {NetworkHelper} from '../helpers/network.helper';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../environments/environment';
import {StorageService} from './storage.service';

@Injectable()
export class CouponService {
    private readonly hostUrl: string;

    constructor(private networkHelper: NetworkHelper, private storage: StorageService) {
        this.hostUrl = environment.host;
    }

    add(couponData: any): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/coupon/create`, couponData);
    }

    getById(id: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/coupon/${id}`, {});
    }

    edit(id: string, data: any): Observable<any> {
        return this.networkHelper.patch(`${this.hostUrl}/coupon/${id}`, data);
    }

    del(id: string): Observable<any> {
        return this.networkHelper.del(`${this.hostUrl}/coupon/${id}`, {});
    }

    all(page: number, itemsPerPage: number, userId?: string, keyword?: string): Observable<any> {
        const query: any = {
            page: page,
            itemsPerPage: itemsPerPage
        };

        if (userId) {
            query.userId = userId;
        }
        if (keyword) {
            query.keyword = keyword;
        }
        return this.networkHelper.get(`${this.hostUrl}/coupon/all`, query);
    }
}
