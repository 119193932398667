import {Component, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-currencies',
    templateUrl: 'currencies.template.html'
})
export class CurrenciesComponent implements OnInit {

    constructor(public snackBar: MatSnackBar,
                private route: ActivatedRoute,
                private router: Router) {
    }

    ngOnInit() {
    }
}
