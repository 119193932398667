import {AfterViewInit, Component, ElementRef} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from '../../../services/storage.service';
import {BaseHeaderComponent} from '../layouts/base-header/base-header.component';

@Component({
    selector: 'app-admin-header',
    templateUrl: './admin-header.template.html'
})

export class AdminHeaderComponent extends BaseHeaderComponent implements AfterViewInit {

    constructor(protected router: Router,
                protected storage: StorageService,
                protected element: ElementRef,
                protected translate: TranslateService) {
        super(storage, router, element, translate);
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
    }
}
