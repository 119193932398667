import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {NetworkHelper} from '../helpers/network.helper';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class StatsService {
    private readonly hostUrl: string;

    constructor(private router: Router, private networkHelper: NetworkHelper) {
        this.hostUrl = environment.host;
    }

    getStats(): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/stats`, {});
    }

    getMerchantOrders(merchantId: string, startDate: number, endDate: number, distribution: string): Observable<any> {
        let url = `${this.hostUrl}/stats/merchant-orders/${merchantId}/fulfilled/verified/${startDate}/${endDate}/${distribution}`;
        return this.networkHelper.get(url, {});
    }

    getMerchantOrdersReport(page: number, itemsPerPage: number, merchantId: string, startDate: number, endDate: number): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/stats/merchant-orders-by-date/${page}/${itemsPerPage}/${merchantId}/fulfilled/verified/${startDate}/${endDate}`, {});
    }

    getMerchantOrdersByProduct(merchantId: string, startDate: number, endDate: number, page?: number, itemsPerPage?: number): Observable<any> {
        let url = `${this.hostUrl}/stats/merchant-orders-by-product/${merchantId}/fulfilled/verified/${startDate}/${endDate}`;
        if (page && itemsPerPage) {
            url += `/${page}/${itemsPerPage}`;
        }

        return this.networkHelper.get(url, {});
    }

    getMerchantRefunds(merchantId: string, startDate: number, endDate: number): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/stats/merchant-refunds/${merchantId}/${startDate}/${endDate}`, {});
    }

    getMerchantRefundsReport(page: number, itemsPerPage: number, merchantId: string, startDate: number, endDate: number): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/stats/merchant-refunds-by-date/${page}/${itemsPerPage}/${merchantId}/${startDate}/${endDate}`, {});
    }

    getMerchantPayoutsReport(page: number, itemsPerPage: number, merchantId: string, startDate: number, endDate: number): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/stats/merchant-payouts-by-date/${page}/${itemsPerPage}/${merchantId}/${startDate}/${endDate}`, {});
    }

    getCommissions(userId: string, startDate: number, endDate: number): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/stats/affiliate-commissions/${userId}/${startDate}/${endDate}`, {});
    }

    getCommissionsReport(page: number, itemsPerPage: number, userId: string, startDate: number, endDate: number): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/stats/affiliate-commissions-by-date/${page}/${itemsPerPage}/${userId}/${startDate}/${endDate}`, {});
    }

    getCommissionsByProduct(userId: string, startDate: number, endDate: number, page?: number, itemsPerPage?: number): Observable<any> {
        let url = `${this.hostUrl}/stats/affiliate-commissions-by-product/${userId}/${startDate}/${endDate}`;
        if (page && itemsPerPage) {
            url += `/${page}/${itemsPerPage}`;
        }

        return this.networkHelper.get(url, {});
    }

    getCommissionsByMerchant(userId: string, startDate: number, endDate: number, page?: number, itemsPerPage?: number): Observable<any> {
        let url = `${this.hostUrl}/stats/affiliate-commissions-by-merchant/${userId}/${startDate}/${endDate}`;
        if (page && itemsPerPage) {
            url += `/${page}/${itemsPerPage}`;
        }

        return this.networkHelper.get(url, {});
    }

    getCommissionsByLevel(userId: string, startDate: number, endDate: number): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/stats/affiliate-commissions-by-level/${userId}/${startDate}/${endDate}`, {});
    }

    getAffiliateInvoices(page: number, itemsPerPage: number, userId: string, startDate: number, endDate: number): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/stats/affiliate-payouts/${page}/${itemsPerPage}/${userId}/${startDate}/${endDate}`, {});
    }

    getPartnerCommissions(userId: string, startDate: number, endDate: number): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/stats/partner-commissions/${userId}/${startDate}/${endDate}`, {});
    }

    getPartnerCommissionsReport(page: number, itemsPerPage: number, userId: string, startDate: number, endDate: number): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/stats/partner-commissions-by-date/${page}/${itemsPerPage}/${userId}/${startDate}/${endDate}`, {});
    }

    getPartnerCommissionsByLevel(userId: string, startDate: number, endDate: number): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/stats/partner-commissions-by-level/${userId}/${startDate}/${endDate}`, {});
    }

    getPartnerCommissionsByOrderType(userId: string, startDate: number, endDate: number): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/stats/partner-commissions-by-order-type/${userId}/${startDate}/${endDate}`, {});
    }

    getPartnerInvoices(page: number, itemsPerPage: number, userId: string, startDate: number, endDate: number): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/stats/partner-payouts/${page}/${itemsPerPage}/${userId}/${startDate}/${endDate}`, {});
    }
}
