import {Injectable} from '@angular/core';
import {NetworkHelper} from '../helpers/network.helper';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../environments/environment';

@Injectable()
export class CategoryService {
    private readonly hostUrl: string;

    constructor(private networkHelper: NetworkHelper) {
        this.hostUrl = environment.host;
    }

    all(page, itemsPerPage, type): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/category/all/${page}/${itemsPerPage}/${type}`, {});
    }

    addSubCategory(name: string, categoryId: string): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/category/sub-category/${categoryId}`, {name: name});
    }

    allSubCategoriesForCategory(categoryId: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/category/sub-categories/${categoryId}`, {});
    }

    getCategoriesForUser(userId: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/product/categories/${userId}`, {});
    }

    getSubCategoriesForUser(userId: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/product/sub-categories/${userId}`, {});
    }

    allContentCategories(page, itemsPerPage): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/content-category/all/${page}/${itemsPerPage}`, {});
    }

    addContentCategory(name: string): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/content-category`, {name: name});
    }

    getContentCategoriesForUser(userId: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/product/content-categories/${userId}`, {});
    }
}
