import {Component, Input, OnInit} from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'app-line-chart',
    templateUrl: 'line-chart.component.html'
})

export class LineChartComponent implements OnInit {
    @Input()
    chartData: any[];

    @Input()
    chartLabels: any[];

    @Input()
    type: string;

    @Input()
    maintainAspectRatio: boolean = true;

    @Input()
    hasXAxis: boolean = true;

    @Input()
    hasYAxis: boolean = true;

    @Input()
    hasXTick: boolean = true;

    @Input()
    hasYTick: boolean = true;

    @Input()
    xAxisTitle: string = '';

    @Input()
    yAxisTitle: string = '';

    chartIsReady = false;

    chartOptions: any;

    chartColors: any[] = [{
        backgroundColor: 'rgba(55,188,237, .3)',
        borderColor: 'rgb(28,146,190)',
        pointBackgroundColor: 'rgba(55,188,237, 0)',
        pointBorderColor: 'rgba(255,104,1, 0)',
        pointHoverBackgroundColor: 'rgb(255,255,255)',
        pointHoverBorderColor: 'rgb(55,188,237)',
        borderWidth: 1
    }];

    ngOnInit() {
        const chartOptions: any = {
            tooltips: {
                callbacks: {
                    title: function (tooltipItems, data) {
                        if (tooltipItems.length === 0) {
                            return;
                        }
                        return moment(tooltipItems[0].xLabel).format('MMM DD');
                    }
                }
            },
            responsive: true,
            maintainAspectRatio: this.maintainAspectRatio,
            legend: {
                display: false
            },
            scales: {
                xAxes: [{
                    display: this.hasXTick,
                    type: 'time',
                    gridLines: {
                        display: this.hasXAxis
                    }
                }],
                yAxes: [
                    {
                        display: this.hasYTick,
                        gridLines: {
                            display: this.hasYAxis
                        }
                    }
                ]
            }
        };

        if (this.xAxisTitle) {
            chartOptions.scales.xAxes.scaleLabel = {display: true, labelString: this.xAxisTitle};
        }

        if (this.yAxisTitle) {
            chartOptions.scales.yAxes.scaleLabel = {display: true, labelString: this.yAxisTitle};
        }

        this.chartOptions = chartOptions;
        this.chartIsReady = true;
    }
}
