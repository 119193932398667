import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AuthService} from '../../../../services/auth.service';
import {UserService} from '../../../../services/user.service';
import {MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {isNullOrUndefined} from 'util';
import {StorageService} from '../../../../services/storage.service';
import {LoggerService} from '../../../../services/logger.service';
import * as hopscotch from 'hopscotch';

import {WaitlistComponent} from '../../../../views/merchant/waitlist/waitlist.component';
import {ConfigurationService} from '../../../../services/configuration.service';
import {AuthLayoutComponent} from '../auth-layout/auth-layout.component';

@Component({
    selector: 'app-main-layout',
    templateUrl: './main-layout.template.html'
})
export class MainLayoutComponent extends AuthLayoutComponent implements OnInit {

    isAdmin: boolean = false;
    isMerchant: boolean = false;
    isAffiliate: boolean = false;
    isUser: boolean = false;
    inMerchantWaitlist = false;
    isGuest: boolean = false;


    langChanged: boolean = false;
    currentLang: string = '';

    constructor(protected router: Router,
                public route: ActivatedRoute,
                private dialogAdd: MatDialog,
                private dialogMnemonic: MatDialog,
                public translate: TranslateService,
                protected authService: AuthService,
                protected userService: UserService,
                protected storage: StorageService,
                public snackBar: MatSnackBar,
                private logger: LoggerService,
                protected configuration: ConfigurationService) {

        super(router, route, translate, storage, configuration, authService, userService);
    }

    setLang() {
        localStorage.setItem('lang', this.currentLang);
        this.translate.use(this.currentLang);
    }

    ngOnInit() {
        super.ngOnInit();

        if (!this.isAuthenticated) {
            this.isGuest = true;
            return;
        }

        const role = this.storage.getItem('role');
        this.isAdmin = ['superadmin', 'admin'].includes(role);
        this.isUser = ['user'].includes(role);
        this.isMerchant = role === 'merchant';
        this.isAffiliate = role === 'affiliate';

        if (localStorage.getItem('lang')) {
            this.currentLang = localStorage.getItem('lang');
        } else if (this.translate.getBrowserLang()) {
            const langCandidate = this.translate.getBrowserLang();
            if (this.env.supportedLangs.map(langObj => langObj.ISOCode).includes(langCandidate)) {
                this.currentLang = langCandidate;
            } else {
                this.currentLang = 'en';
            }
        } else {
            this.currentLang = 'en';
        }
        this.setLang();
        // this.translate.use(this.currentLang);

        this.translate.onLangChange.subscribe(() => {
            if (hopscotch.getCurrTour()) {
                this.langChanged = true;
                hopscotch.endTour(false);
            }
            this.organizeTours();
        });

        // Close sidenav after route change in mobile
        this.router.events.filter(event => event instanceof NavigationEnd)
            .subscribe((routeChange: NavigationEnd) => {
                if (routeChange.url !== '/' && hopscotch.getCurrTour()) {
                    hopscotch.endTour(false);
                }
                this.organizeTours();
            });
    }

    changeJustRegistered() {
        this.userService.changeJustRegistered()
            .subscribe(
                (result) => {
                    this.userService.getCurrentUser();
                },
                (err) => {
                    this.snackBar.open(err, this.translate.instant('CLOSE'), {duration: 3000});
                });
    }

    tourSteps(steps: any[]): any {
        return {
            id: 'hello-wallet',
            showPrevButton: true,
            smoothScroll: false,
            onEnd: () => {
                if (!this.langChanged) {
                    this.changeJustRegistered();
                    this.snackBar.open(this.translate.instant('TOUR_STEPS.EXPLORE_FEATURES'),
                        this.translate.instant('CLOSE'), {duration: 5000});
                    this.langChanged = false;
                }
            },
            onClose: () => {
                this.changeJustRegistered();
                this.snackBar.open(this.translate.instant('TOUR_STEPS.NOT_DISTURB'),
                    this.translate.instant('CLOSE'), {duration: 4000});
            },
            steps: steps
        };
    }

    merchantWaitlist() {
        let dialogRef: MatDialogRef<WaitlistComponent>;
        const config = {
            disableClose: false,
            data: {
                inMerchantWaitlist: this.inMerchantWaitlist
            }
        };
        dialogRef = this.dialogAdd.open(WaitlistComponent, config);
        dialogRef.updateSize('400px');
        dialogRef.afterClosed().subscribe((result) => {
            if (result === true) {
                this.inMerchantWaitlist = true;
            }
        });
    }

    signout() {
        this.authService.logout()
            .subscribe(
                (result) => {
                    window.location.reload();
                },
                (err) => {
                    this.router.navigate(['/']);
                });
    }

    private organizeTours() {
        this.userService.getCurrentUser()
            .subscribe(
                (user) => {
                    if (user.waitlist) {
                        // his.storage.setItem('inMerchantWaitlist', true);
                        this.inMerchantWaitlist = true;
                    }

                    if (isNullOrUndefined(user.justRegistered) || user.justRegistered === false) {
                        return;
                    }
                    if (this.router.url !== '/') {
                        // hopscotch.endTour(true);
                        return;
                    }

                    // Init User Tour
                    setTimeout(() => {
                        const steps: any[] = [
                            {
                                title: this.translate.instant('TOUR_STEPS.STEPS.FIRST.TITLE'),
                                content: this.translate.instant('TOUR_STEPS.STEPS.FIRST.CONTENT'),
                                target: 'walletsContainer',
                                placement: 'top',
                                xOffset: 200,
                                yOffset: 100
                            },
                            {
                                title: this.translate.instant('TOUR_STEPS.STEPS.SECOND.TITLE'),
                                content: this.translate.instant('TOUR_STEPS.STEPS.SECOND.CONTENT'),
                                target: 'wallet0',
                                placement: 'top',
                                yOffset: 100
                            },
                            {
                                title: this.translate.instant('TOUR_STEPS.STEPS.THIRD.TITLE'),
                                content: this.translate.instant('TOUR_STEPS.STEPS.THIRD.CONTENT'),
                                target: 'wallet0',
                                placement: 'top',
                                yOffset: 100
                            },
                            {
                                title: this.translate.instant('TOUR_STEPS.STEPS.FOURTH.TITLE'),
                                content: this.translate.instant('TOUR_STEPS.STEPS.FOURTH.CONTENT'),
                                target: 'expandWallet0',
                                placement: 'bottom',
                                arrowOffset: 252,
                                xOffset: -260,
                                yOffset: 0
                            }
                        ];
                        hopscotch.configure({
                            'i18n': {
                                'skipBtn': this.translate.instant('TOUR_STEPS.SKIP'),
                                'nextBtn': this.translate.instant('TOUR_STEPS.NEXT'),
                                'prevBtn': this.translate.instant('TOUR_STEPS.PREV'),
                                'doneBtn': this.translate.instant('TOUR_STEPS.DONE')
                            }
                        });
                        hopscotch.startTour(this.tourSteps(steps));
                    }, 4000);
                },
                (error) => {
                    this.logger.error(error);
                });
    }


}
