import {Injectable} from '@angular/core';
import {NetworkHelper} from '../helpers/network.helper';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../environments/environment';
import {StorageService} from './storage.service';

@Injectable()
export class DistributionService {
    private readonly hostUrl: string;

    constructor(private networkHelper: NetworkHelper, private storage: StorageService) {
        this.hostUrl = environment.host;
    }

    allDistributions(page, itemsPerPage, userId): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/distribution/all/${page}/${itemsPerPage}/${userId}`, {});
    }

    addDistribution(refType: string, refId: string, refToken: string = ''): Observable<any> {
        return this.networkHelper.post(`${this.hostUrl}/distribution/create`, {refType: refType, refId: refId, refToken: refToken});
    }

    getDistribution(distributionId: string): Observable<any> {
        return this.networkHelper.get(`${this.hostUrl}/distribution/${distributionId}`, {});
    }
}
