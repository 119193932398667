import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../../services/auth.service';
import {UserService} from '../../../../services/user.service';
import {MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from '../../../../services/storage.service';
import {LoggerService} from '../../../../services/logger.service';
import {ConfigurationService} from '../../../../services/configuration.service';
import {AuthLayoutComponent} from '../auth-layout/auth-layout.component';

@Component({
    selector: 'app-admin-layout',
    templateUrl: './admin-layout.template.html'
})
export class AdminLayoutComponent extends AuthLayoutComponent implements OnInit {
    user: any = {};
    currentLang: string = '';

    constructor(protected router: Router,
                public route: ActivatedRoute,
                public translate: TranslateService,
                protected authService: AuthService,
                protected userService: UserService,
                protected storage: StorageService,
                public snackBar: MatSnackBar,
                private logger: LoggerService,
                protected configuration: ConfigurationService) {
        super(router, route, translate, storage, configuration, authService, userService);
    }

    setLang() {
        localStorage.setItem('lang', this.currentLang);
        this.translate.use(this.currentLang);
    }

    ngOnInit() {
        super.ngOnInit();

        // todo do we need implement language setting inside BaseLayoutComponent layout
        if (localStorage.getItem('lang')) {
            this.currentLang = localStorage.getItem('lang');
        } else if (this.translate.getBrowserLang()) {
            this.currentLang = this.translate.getBrowserLang();
        } else {
            this.currentLang = 'en';
        }
        this.translate.use(this.currentLang);

        if (['user'].indexOf(this.storage.getItem('role')) !== -1) {
            this.router.navigate(['/']);
        }
    }

    signout() {
        this.authService.logout()
            .subscribe(
                (result) => {
                    window.location.reload();
                },
                (err) => {
                    this.router.navigate(['/']);
                });
    }
}
