import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MatDialogRef, MatSnackBar} from '@angular/material';

@Component({
    selector: 'app-upsell-details',
    templateUrl: 'upsell-details.template.html'
})
export class UpsellDetailsComponent implements OnInit {

    public type: string;
    public title: string;
    public innerHtml: string = '';
    public videoLink: string = '';
    public reducedPrice: number = 0;
    public upsell: any;
    private youtubeReg: RegExp = /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]+)/;
    private vimeoReg: RegExp = /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i;

    contentTypes: any[] = [
        {
            name: 'PRODUCT_STUFF.UPSELLS.DETAILS_DIALOG.RADIOS.TEXT',
            value: 'TEXT'
        },
        {
            name: 'PRODUCT_STUFF.UPSELLS.DETAILS_DIALOG.RADIOS.VIDEO',
            value: 'VIDEO'
        }
    ];

    // Quill text editor configurations
    quillConfigs = {
        toolbar: {
          container: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],     // ordered, unordered lists
            [{ 'direction': 'rtl' }],                         // text direction (ltr or rtl)
            [{ 'size': [false, 'small', 'large', 'huge'] }],  // custom dropdown
            [{ 'color': [] }, { 'background': [] }],          // color and background
            [{ 'align': [] }]                                 // text alignment
          ]
        }
    };

    constructor(public dialogRef: MatDialogRef<UpsellDetailsComponent>,
                private snackBar: MatSnackBar,
                public translate: TranslateService) {

    }

    ngOnInit() {
        this.type = this.upsell.contentType ? this.upsell.contentType : 'TEXT';
        this.title = this.upsell.contentTitle ? this.upsell.contentTitle : '';
        this.type === 'TEXT' ? this.innerHtml = this.upsell.contentText : this.videoLink = this.upsell.contentText;
        this.reducedPrice = this.upsell.reducedPrice ? this.upsell.reducedPrice : 0;
    }

    save() {
        if (this.reducedPrice >= this.upsell.price) {
            this.snackBar.open(this.translate.instant('MERCHANT_STUFF.PRODUCTS.CAUTIONS.REDUCED_PRICE_ALERT',
                {price: this.upsell.price}), this.translate.instant('CLOSE'), {duration: 3000});
        } else {
        this.upsell.contentType = this.type;
        this.upsell.contentTitle = this.title;
        this.upsell.contentText = this.type === 'TEXT' ? this.innerHtml : this.videoLink;
            this.upsell.reducedPrice = this.reducedPrice;
        if (this.type === 'VIDEO') {
            if (this.urlValidator(this.videoLink)) {
                this.dialogRef.close(this.upsell);
            } else {
                this.snackBar.open(this.translate.instant('PRODUCT_STUFF.UPSELLS.DETAILS_DIALOG.VIDEO_URL_ERROR'),
                    this.translate.instant('CLOSE'), {duration: 3000});
            }
        } else {
            this.dialogRef.close(this.upsell);
        }
    }
    }

    close() {
        this.dialogRef.close(null);
    }

    urlValidator(url: string): boolean {
        if (url.match(this.youtubeReg)) {
            return true;
        }
        if (url.match(this.vimeoReg)) {
            return true;
        }
        return false;
    }

}
