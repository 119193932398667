import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material';

import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from '../../../services/storage.service';
import {AuthService} from '../../../services/auth.service';
import {Subscription} from 'rxjs/Subscription';
import {UserService} from '../../../services/user.service';

@Component({
    selector: 'app-user-panel',
    templateUrl: 'user-panel.template.html'
})
export class UserPanelComponent implements OnInit, OnDestroy {
    env: any = environment;
    showNavDashboard = false;
    showUserPanelActions: boolean = false;
    user: any = {};
    userName: string = '';
    userRole: string = '';
    lang: string = '';

    private getCurrentUserSubscription: Subscription;

    constructor(public translate: TranslateService,
                public snackBar: MatSnackBar,
                private router: Router,
                public storage: StorageService,
                private authService: AuthService,
                private userService: UserService) {
        this.getCurrentUserSubscription = this.userService.getCurrentUser()
            .subscribe((res) => {
                this.user = res;
            });
    }

    ngOnInit() {
        this.userName = this.storage.getItem('name');
        this.userRole = this.storage.getItem('role');
    }

    signout() {
        this.authService.logout()
            .subscribe(
                (result) => {
                    window.location.reload();
                },
                (err) => {
                    this.router.navigate(['/']);
                });
    }

    ngOnDestroy() {
        if (this.getCurrentUserSubscription) {
            this.getCurrentUserSubscription.unsubscribe();
        }
    }

    footerToggle() {
        this.showUserPanelActions = !this.showUserPanelActions;
    }
}
