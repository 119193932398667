import {Pipe, PipeTransform} from '@angular/core';
import {Utl} from '../helpers/utl';

 @Pipe({name: 'toFixed'})
 export class ToFixedPipe implements PipeTransform {


    transform(value: any, precision: number) {
        return Utl.formatFixed(value, precision);
     }
 }
