import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {NetworkHelper} from '../helpers/network.helper';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class PayoutService {
    private readonly hostUrl: string;

    constructor(private router: Router, private networkHelper: NetworkHelper) {
        this.hostUrl = environment.host;
    }

    all(page: number, itemsPerPage: number, userId: string, status: string,
        sortType: string, sortDir: string, startDate: number, endDate: number): Observable<any> {
        const url = `${this.hostUrl}/payout/all/${page}/${itemsPerPage}/${userId}/${status}/${sortType}/${
                    sortDir}/${startDate}/${endDate}`;
        return this.networkHelper.get(url);
    }

    pay(id: string): Observable<any> {
        const url = `${this.hostUrl}/payout/pay`;
        return this.networkHelper.patch(url, {id: id});
    }
}
