import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'decimalShrink'})

export class DecimalShrinkerPipe implements PipeTransform {
    transform(value: number, decimalDigitsCount: number): string {
        // check value existence
        if (!value) {
            return `${value}`;
        }

        let num = String(value); // convert number to string

        // check if the number is in exponential notation
        const isExponential = num.match(/\d+\.?\d*e[\+\-]*\d+/i);

        // convert exponential notation to decimal
        if (isExponential) {
            const zero = '0';
            const numberParts = num.toLowerCase().split('e'); // split number into coefficient and exponent
            const exp = Number(numberParts.pop()); // exponential part
            let expAbs = Math.abs(exp); // number of zeroes
            const sign = exp / expAbs;
            const coeffArray = numberParts[0].split('.');
            if (sign === -1) {
                num = `${zero}.${new Array(expAbs).join(zero) + coeffArray.join('')}`;
            } else {
                const decimal = coeffArray[1];
                if (decimal) {
                    expAbs = expAbs - (decimal.length - 1);
                }
                num = coeffArray.join('') + new Array(expAbs).join(zero);
            }
            return num;
        }

        // check if the number is decimal
        const isDecimal = num.match(/\./);

        // shrink decimal fraction digits to given digits count
        if (isDecimal) {
            const numberParts = num.split('.'); // split number into integer and decimal
            const integerPart = numberParts[0]; // integer part
            const decimalPart = (numberParts[1]).slice(0, decimalDigitsCount); // shrink decimal part
            // return number with no trailing zeroes
            return (Number(`${integerPart}.${decimalPart}`)).toString();
        } else {
            return num;
        }
    }
}
