import {Component, Input, Output, OnInit, EventEmitter} from '@angular/core';
import {Utl} from '../../../helpers/utl';
import {BehaviorSubject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

export interface MultipleSelectValue {
    name: string;
    value: string;
}

@Component({
    selector: 'app-multiple-select',
    templateUrl: 'multiple-select.template.html'
})

export class MultipleSelectComponent implements OnInit {

    private _selectsSubject = new BehaviorSubject<MultipleSelectValue[]>([]);
    private _optionsSubject = new BehaviorSubject<MultipleSelectValue[]>([]);

    @Input() set options(data: MultipleSelectValue[]) {
        this._optionsSubject.next(data);
    }

    get options(): MultipleSelectValue[] {
        return this._optionsSubject.getValue();
    }

    @Input() set selects(data: MultipleSelectValue[]) {
        this._selectsSubject.next(data);
    }

    get selects(): MultipleSelectValue[] {
        return this._selectsSubject.getValue();
    }

    @Input() namePath: string;
    @Input() placeholder: string = '';
    @Input() classnames: string = '';
    @Output() output: EventEmitter<MultipleSelectValue[]> = new EventEmitter();

    _options: MultipleSelectValue[];
    _selects: MultipleSelectValue[];
    outputData: MultipleSelectValue[];

    all: MultipleSelectValue = {
        name: 'ALL',
        value: 'ALL'
    };

    constructor(public translate: TranslateService) {
    }

    ngOnInit(): void {

        this._optionsSubject.subscribe((items) => {
            this._options = [];
            this._options = items && items.length ? [...items] : [];

            this._options.unshift(this.all);
        });

        this._selectsSubject.subscribe((items) => {
            this._selects = [];
            this._selects = items && items.length ? [...items] : [];

            if (this._selects.length && this._selects.length === this._options.length - 1) {
                this._selects.unshift(this.all);
            }
        });

    }

    checkOption(option: MultipleSelectValue): void {
        const exists = Utl.findElemInArray(this._selects, 'value', 'ALL');
        if (option.value === 'ALL') {
            if (exists) { // if 'ALL' option selected
                this.updateOptions(this._options);
            } else { // if 'ALL' option deselected
                this.updateOptions();
            }
        }
        // if all options are selected select also 'ALL' option
        if (this._selects.length === this._options.length - 1 && !exists) {
            this.updateOptions(this._options);
        }
        // if all options are selected and one option is deselecting
        if (option.value !== 'ALL' && this._selects.length === this._options.length - 1) {
            const removed = [
                this.all,
                option
            ];
            const options = Utl.removeItemsFromArray(this._selects.splice(0), removed, 'value');
            this.updateOptions(options);
        }
        this.makeOutput();
    }

    updateOptions(options?: MultipleSelectValue[]) {
        this._selects = options ? options : [];
    }

    makeOutput(): void {
        this.outputData = [];
        this.outputData = [...this._selects];    // cloning array
        this.outputData = Utl.removeItemsFromArray(this.outputData,
            [this.all], 'value');
        this.output.emit(this.outputData);
    }

    compareValues(opOne: MultipleSelectValue, opTwo: MultipleSelectValue): boolean {
        return opOne.value === opTwo.value;
    }
}
